import React from 'react';
import { Home, Users, Heart, Clock, Coffee, Utensils, Truck, MessageCircle } from 'lucide-react';
import { motion } from 'framer-motion';
import Seo from '../components/Seo';
import FeatureCard from '../components/FeatureCard';

import hero from '../assets/images/respite_care.jpeg';
import respiteCareImg from '../assets/images/respite_care_service.jpeg';

/**
 * The RespiteCare component renders the page for Respite Care services.
 * It includes sections that detail the services provided, the environment, 
 * and the safety measures in place, along with relevant images and icons.
 * The component now includes animations for a more engaging user experience.
 *
 * @component
 * @example
 * return (
 *   <RespiteCare />
 * )
 */
const RespiteCare = () => {
    const features = [
        {
            icon: Home,
            title: "Temporary Stay",
            description: "Providing a comfortable, home-like environment for short-term care needs."
        },
        {
            icon: Users,
            title: "Experienced Carers",
            description: "Our team is trained to offer compassionate and professional care for your loved ones."
        },
        {
            icon: Heart,
            title: "Personalised Attention",
            description: "We ensure that each individual receives tailored care to meet their specific needs."
        },
        {
            icon: Clock,
            title: "Flexible Scheduling",
            description: "We offer respite care that fits your schedule, whether it's for a few hours, days, or weeks."
        }
    ];

    return (
        <motion.div
            className="animate-fadeIn"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <Seo
                title="Respite Care Services | Sandbourne Care"
                description="Offering short-term care solutions to give carers a break while ensuring the best care for your loved ones. Explore our Respite Care services."
                keywords="Respite Care, Sandbourne Care, temporary care, short-term care, professional carers"
                canonicalUrl="https://www.sandbournecare.co.uk/respite-care"
                ogType="website"
                ogTitle="Respite Care Services | Sandbourne Care"
                ogDescription="Discover how Sandbourne Care provides respite care services, offering temporary care in a comfortable and supportive environment."
                ogImage={hero}
                ogUrl="https://www.sandbournecare.co.uk/respite-care"
            />

            <motion.section
                className="relative bg-gradient-to-r from-teal-600 to-teal-400 text-white py-20 px-4"
                initial={{ y: -50 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <div className="container mx-auto max-w-6xl flex flex-col md:flex-row items-center">
                    <div className="md:w-1/2 mb-8 md:mb-0">
                        <h1 className="text-4xl md:text-5xl font-bold mb-6">Respite Care Services</h1>
                        <p className="text-xl md:text-2xl mb-8">
                            Offering a short-term care solution to give carers a break while ensuring the best care for your loved ones.
                        </p>
                    </div>
                    <div className="md:w-1/2 relative">
                        <img src={hero} alt="Respite Care facility" className="rounded-lg shadow-lg" />
                        <div className="absolute inset-0 bg-teal-600 opacity-20 rounded-lg"></div>
                    </div>
                </div>
            </motion.section>

            <motion.section
                className="py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-6xl">
                    <h2 className="text-3xl font-bold mb-12 text-center">Our Approach to Respite Care</h2>
                    <motion.div
                        className="grid grid-cols-1 md:grid-cols-2 gap-8"
                        variants={{
                            hidden: { opacity: 0 },
                            show: {
                                opacity: 1,
                                transition: {
                                    staggerChildren: 0.2
                                }
                            }
                        }}
                        initial="hidden"
                        animate="show"
                    >
                        {features.map((feature, index) => (
                            <motion.div
                                key={index}
                                variants={{
                                    hidden: { opacity: 0, y: 20 },
                                    show: { opacity: 1, y: 0 }
                                }}
                            >
                                <FeatureCard {...feature} />
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </motion.section>

            <motion.section
                className="bg-gray-100 py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-6xl">
                    <div className="flex flex-col md:flex-row items-centre">
                        <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
                            <h2 className="text-3xl font-bold mb-6">Experience Our Respite Care</h2>
                            <p className="text-lg text-gray-700 mb-4">
                                Our respite care offers a temporary yet supportive environment with all the amenities needed to ensure comfort and care. Highlights include:
                            </p>
                            <ul className="space-y-2 text-lg text-gray-700">
                                <li className="flex items-start">
                                    <Home className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Comfortable, private rooms</span>
                                </li>
                                <li className="flex items-start">
                                    <Coffee className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Recreational areas for relaxation</span>
                                </li>
                                <li className="flex items-start">
                                    <Utensils className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Nutritious meals catered to individual dietary needs</span>
                                </li>
                                <li className="flex items-start">
                                    <Truck className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Engaging activities and outings</span>
                                </li>
                            </ul>
                        </div>
                        <div className="md:w-1/2">
                            <img src={respiteCareImg} alt="Respite care residents enjoying communal area" className="rounded-lg shadow-lg" />
                        </div>
                    </div>
                </div>
            </motion.section>

            <motion.section
                className="py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-6xl">
                    <h2 className="text-3xl font-bold mb-8 text-centre">Our Respite Care Services</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold mb-4">Temporary Personal Care</h3>
                            <ul className="space-y-2 text-gray-700">
                                <li>• Assistance with daily activities</li>
                                <li>• Medication management</li>
                                <li>• Continence care</li>
                                <li>• Mobility assistance</li>
                            </ul>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold mb-4">Health Monitoring</h3>
                            <ul className="space-y-2 text-gray-700">
                                <li>• Regular health check-ups</li>
                                <li>• Access to healthcare services</li>
                                <li>• Physical therapy sessions</li>
                                <li>• Emotional and mental support</li>
                            </ul>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold mb-4">Social Engagement</h3>
                            <ul className="space-y-2 text-gray-700">
                                <li>• Group activities and entertainment</li>
                                <li>• Creative hobby groups</li>
                                <li>• Community and family interaction</li>
                                <li>• Regular social outings</li>
                            </ul>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold mb-4">Facilities & Comfort</h3>
                            <ul className="space-y-2 text-gray-700">
                                <li>• Comfortable living arrangements</li>
                                <li>• Well-maintained outdoor spaces</li>
                                <li>• Gourmet meals prepared daily</li>
                                <li>• Full housekeeping services</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </motion.section>

            <motion.section
                className="py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-4xl text-centre">
                    <h2 className="text-3xl font-bold mb-6">Discover Our Respite Care Services</h2>
                    <p className="text-lg text-gray-700 mb-8">
                        If you need temporary care for your loved ones, we're here to assist. Contact us to learn more about how we can support you and your family.
                    </p>
                    <a href="/contact" className="btn-primary inline-flex items-centre">
                        Get in Touch <MessageCircle className="ml-2 w-5 h-5" />
                    </a>
                </div>
            </motion.section>
        </motion.div>
    );
};

export default RespiteCare;