import React from 'react';
import { BookOpen, Users, Briefcase, Home, MessageCircle } from 'lucide-react';

// Import components
import Seo from '../components/Seo';  // Import the Seo component

// Import assets
import HeroImage from '../assets/images/hero.jpeg';

const FeatureCard = ({ icon: Icon, title, description }) => (
    <div className="bg-white p-6 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg">
        <Icon className="w-12 h-12 text-teal-600 mb-4" />
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
    </div>
);

const LearningDisabilitySupport = () => {
    const features = [
        {
            icon: BookOpen,
            title: "Personalized Learning Plans",
            description: "We develop individualized plans tailored to each person's unique learning needs and goals."
        },
        {
            icon: Users,
            title: "Social Skills Development",
            description: "Our programs focus on enhancing social interaction and communication skills in various settings."
        },
        {
            icon: Briefcase,
            title: "Vocational Training",
            description: "We provide job skills training and support in finding and maintaining employment."
        },
        {
            icon: Home,
            title: "Independent Living Skills",
            description: "We offer training in daily living skills to promote greater independence and self-reliance."
        }
    ];

    return (
        <div className="animate-fadeIn">
            {/* SEO Component for optimizing the Learning Disability Support page */}
            <Seo
                title="Adult Learning Disability Support Services | Sandbourne Care"
                description="Empowering adults with learning disabilities through personalized plans, social skills development, and vocational training at Sandbourne Care."
                keywords="Adult Learning Disability Support, Sandbourne Care, learning disability services, vocational training, independent living"
                canonicalUrl="https://www.sandbournecare.co.uk/learning-disability-support"
                ogType="website"
                ogTitle="Adult Learning Disability Support Services | Sandbourne Care"
                ogDescription="Discover how Sandbourne Care supports adults with learning disabilities in achieving their full potential through comprehensive support services."
                ogImage={HeroImage}  // Replace with actual image path if needed
                ogUrl="https://www.sandbournecare.co.uk/learning-disability-support"
            />

            <section className="bg-gradient-primary text-white py-20 px-4">
                <div className="container mx-auto max-w-4xl text-center">
                    <h1 className="text-4xl md:text-5xl font-bold mb-6">Adult Learning Disability Support Services</h1>
                    <p className="text-xl md:text-2xl">
                        Empowering adults with learning disabilities to achieve their full potential.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4">
                <div className="container mx-auto max-w-6xl">
                    <div className="mb-12 text-center">
                        <h2 className="text-3xl font-bold mb-4">Our Approach to Adult Learning Disability Support</h2>
                        <p className="text-lg text-gray-700 max-w-3xl mx-auto">
                            At Sandbourne Care, we believe that every adult with a learning disability has unique strengths and abilities. Our person-centered approach focuses on empowering individuals to develop skills, build confidence, and lead fulfilling lives.
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {features.map((feature, index) => (
                            <FeatureCard key={index} {...feature} />
                        ))}
                    </div>
                </div>
            </section>

            <section className="bg-gray-100 py-16 px-4">
                <div className="container mx-auto max-w-4xl">
                    <h2 className="text-3xl font-bold mb-8 text-center">Our Learning Disability Support Services</h2>
                    <ul className="space-y-4 text-lg text-gray-700">
                        <li className="flex items-start">
                            <span className="text-teal-600 mr-2">•</span>
                            <span>Cognitive skills development programs</span>
                        </li>
                        <li className="flex items-start">
                            <span className="text-teal-600 mr-2">•</span>
                            <span>Literacy and numeracy support</span>
                        </li>
                        <li className="flex items-start">
                            <span className="text-teal-600 mr-2">•</span>
                            <span>Life skills training (budgeting, cooking, personal care)</span>
                        </li>
                        <li className="flex items-start">
                            <span className="text-teal-600 mr-2">•</span>
                            <span>Social and recreational activities</span>
                        </li>
                        <li className="flex items-start">
                            <span className="text-teal-600 mr-2">•</span>
                            <span>Vocational training and supported employment</span>
                        </li>
                        <li className="flex items-start">
                            <span className="text-teal-600 mr-2">•</span>
                            <span>Assistive technology training</span>
                        </li>
                        <li className="flex items-start">
                            <span className="text-teal-600 mr-2">•</span>
                            <span>Advocacy and self-advocacy skills development</span>
                        </li>
                    </ul>
                </div>
            </section>

            <section className="py-16 px-4">
                <div className="container mx-auto max-w-4xl">
                    <h2 className="text-3xl font-bold mb-8 text-center">Promoting Independence and Community Inclusion</h2>
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <p className="text-lg text-gray-700 mb-6">
                            Our goal is to support adults with learning disabilities in leading independent and fulfilling lives within their communities. We focus on:
                        </p>
                        <ul className="space-y-2 text-gray-700">
                            <li>• Building self-confidence and self-esteem</li>
                            <li>• Developing practical skills for everyday life</li>
                            <li>• Encouraging active participation in community activities</li>
                            <li>• Supporting individuals in making their own choices</li>
                            <li>• Fostering meaningful relationships and social connections</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="bg-teal-50 py-16 px-4">
                <div className="container mx-auto max-w-4xl text-center">
                    <h2 className="text-3xl font-bold mb-6">Get Support for Adult Learning Disabilities</h2>
                    <p className="text-lg text-gray-700 mb-8">
                        If you or a loved one needs support with learning disabilities, we're here to help. Our team is ready to discuss your needs and create a personalized support plan.
                    </p>
                    <a href="/contact" className="btn-primary inline-flex items-center">
                        Contact Us Today <MessageCircle className="ml-2 w-5 h-5" />
                    </a>
                </div>
            </section>
        </div>
    );
};

export default LearningDisabilitySupport;