import React from 'react';
import { Link } from 'react-router-dom';
import { Home, ArrowLeft } from 'react-feather';
import { motion } from 'framer-motion';

const NotFound = () => {
    const containerVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.6,
                when: "beforeChildren",
                staggerChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-primary">
            <motion.div
                className="max-w-md w-full px-6 py-8 bg-white shadow-lg rounded-lg text-center"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
            >
                <motion.h1 variants={itemVariants} className="text-6xl font-bold text-teal-600 mb-4">404</motion.h1>
                <motion.h2 variants={itemVariants} className="text-3xl font-semibold text-gray-800 mb-4">Oops! Page Not Found</motion.h2>
                <motion.p variants={itemVariants} className="text-gray-600 mb-8">
                    It seems you've wandered off the path. Don't worry, we're here to guide you back!
                </motion.p>
                <motion.div variants={itemVariants} className="flex justify-center space-x-4">
                    <Link to="/" className="flex items-center bg-teal-600 text-white font-semibold px-6 py-3 rounded-md hover:bg-teal-700 transition-colors">
                        <Home className="mr-2" size={18} />
                        Go Home
                    </Link>
                    <button onClick={() => window.history.back()} className="flex items-center bg-teal-100 text-teal-700 font-semibold px-6 py-3 rounded-md hover:bg-teal-200 transition-colors">
                        <ArrowLeft className="mr-2" size={18} />
                        Go Back
                    </button>
                </motion.div>
            </motion.div>
        </div>
    );
};

export default NotFound;