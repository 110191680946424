import { Link } from "react-router-dom";
import { ChevronRight } from "lucide-react";

/**
 * ServiceCard component represents a card displaying information about a specific service.
 * @param {Object} props - The properties object.
 * @param {React.ComponentType} props.icon - The icon component representing the service.
 * @param {string} props.title - The title of the service.
 * @param {string} props.description - The description of the service.
 * @param {string} props.link - The link to the detailed page of the service.
 * @returns {JSX.Element} The rendered ServiceCard component.
 */
const ServiceCard = ({ icon: Icon, title, description, link }) => (
    <div className="bg-white p-6 rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
        <Icon className="w-12 h-12 text-teal-600 mb-4" />
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-600 mb-4">{description}</p>
        <Link to={link} className="inline-flex items-center text-teal-600 hover:text-teal-700 transition-colors">
            Learn more <ChevronRight className="ml-1 w-5 h-5" />
        </Link>
    </div>
);

export default ServiceCard;