import React from 'react';
import { Puzzle, Users, BookOpen, Smile, MessageCircle } from 'lucide-react';
import { motion } from 'framer-motion';

// Import components
import Seo from '../components/Seo';

// Import assets
import HeroImage from '../assets/images/hero.jpeg';

const FeatureCard = React.memo(({ icon: Icon, title, description }) => (
    <motion.div
        className="bg-white p-6 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
    >
        <Icon className="w-12 h-12 text-teal-600 mb-4" />
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
    </motion.div>
));

const features = [
    {
        icon: Puzzle,
        title: "Individualized Support Plans",
        description: "We develop tailored support plans that address the unique needs and strengths of each individual with autism."
    },
    {
        icon: Users,
        title: "Social Skills Development",
        description: "Our programs focus on enhancing social interaction and communication skills in various settings."
    },
    {
        icon: BookOpen,
        title: "Educational Support",
        description: "We provide assistance with academic skills and collaborate with schools to ensure consistent support."
    },
    {
        icon: Smile,
        title: "Sensory Integration",
        description: "Our facilities and programs are designed to accommodate sensory sensitivities and promote integration."
    }
];

const AutismSupport = () => {
    return (
        <motion.div
            className="animate-fadeIn"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <Seo
                title="Autism Support Services | Sandbourne Care"
                description="Empowering individuals with autism through personalized support plans, social skills development, and educational support at Sandbourne Care."
                keywords="Autism support, autism care, social skills development, sensory integration, educational support"
                canonicalUrl="https://www.sandbournecare.co.uk/autism-support"
                ogType="website"
                ogTitle="Autism Support Services | Sandbourne Care"
                ogDescription="Learn about our comprehensive autism support services designed to empower individuals with autism at Sandbourne Care."
                ogImage={HeroImage}
                ogUrl="https://www.sandbournecare.co.uk/autism-support"
            />

            <motion.section
                className="bg-gradient-primary text-white py-20 px-4"
                initial={{ y: -50 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <div className="container mx-auto max-w-4xl text-center">
                    <h1 className="text-4xl md:text-5xl font-bold mb-6">Autism Support Services</h1>
                    <p className="text-xl md:text-2xl">
                        Empowering individuals with autism to reach their full potential.
                    </p>
                </div>
            </motion.section>

            <motion.section
                className="py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-6xl">
                    <h2 className="text-3xl font-bold mb-12 text-center">Our Approach to Autism Support</h2>
                    <motion.div
                        className="grid grid-cols-1 md:grid-cols-2 gap-8"
                        variants={{
                            hidden: { opacity: 0 },
                            show: {
                                opacity: 1,
                                transition: {
                                    staggerChildren: 0.1
                                }
                            }
                        }}
                        initial="hidden"
                        animate="show"
                    >
                        {features.map((feature, index) => (
                            <motion.div
                                key={index}
                                variants={{
                                    hidden: { opacity: 0, y: 20 },
                                    show: { opacity: 1, y: 0 }
                                }}
                            >
                                <FeatureCard {...feature} />
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </motion.section>

            <motion.section
                className="bg-gray-100 py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-4xl">
                    <h2 className="text-3xl font-bold mb-8 text-center">Our Autism Support Services</h2>
                    <motion.ul
                        className="space-y-4 text-lg text-gray-700"
                        variants={{
                            hidden: { opacity: 0 },
                            show: {
                                opacity: 1,
                                transition: {
                                    staggerChildren: 0.05
                                }
                            }
                        }}
                        initial="hidden"
                        animate="show"
                    >
                        {[
                            "Social skills groups and workshops",
                            "Life skills training for independent living",
                            "Parent and family support and education"
                        ].map((service, index) => (
                            <motion.li
                                key={index}
                                className="flex items-start"
                                variants={{
                                    hidden: { opacity: 0, x: -20 },
                                    show: { opacity: 1, x: 0 }
                                }}
                            >
                                <span className="text-teal-600 mr-2">•</span>
                                <span>{service}</span>
                            </motion.li>
                        ))}
                    </motion.ul>
                </div>
            </motion.section>

            <motion.section
                className="py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-4xl">
                    <h2 className="text-3xl font-bold mb-8 text-center">Supporting Families</h2>
                    <p className="text-lg text-gray-700 mb-8 text-center">
                        We understand that autism affects the whole family. That's why we offer comprehensive support and resources for parents, siblings, and other family members. Our goal is to empower families with the knowledge and tools they need to support their loved ones with autism.
                    </p>
                    <motion.div
                        className="bg-white p-6 rounded-lg shadow-md"
                        initial={{ scale: 0.9 }}
                        animate={{ scale: 1 }}
                        transition={{ delay: 0.8, duration: 0.3 }}
                    >
                        <h3 className="text-xl font-semibold mb-4">Family Support Services Include:</h3>
                        <ul className="space-y-2 text-gray-700">
                            {[
                                "Parent education workshops",
                                "Sibling support groups",
                                "Family counseling",
                                "Respite care services",
                                "Connection to community resources"
                            ].map((service, index) => (
                                <li key={index}>• {service}</li>
                            ))}
                        </ul>
                    </motion.div>
                </div>
            </motion.section>

            <motion.section
                className="bg-teal-50 py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-4xl text-center">
                    <h2 className="text-3xl font-bold mb-6">Get Started with Autism Support</h2>
                    <p className="text-lg text-gray-700 mb-8">
                        If you're seeking support for yourself or a loved one with autism, we're here to help. Our team is ready to answer your questions and guide you through our services.
                    </p>
                    <motion.a
                        href="/contact"
                        className="btn-primary inline-flex items-center"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        Contact Us Today <MessageCircle className="ml-2 w-5 h-5" />
                    </motion.a>
                </div>
            </motion.section>
        </motion.div>
    );
};

export default AutismSupport;