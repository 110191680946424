import React from 'react';
import { ReactComponent as Logo } from '../assets/images/logo.svg';

const LoadingLogo = () => {
    return (
        <div className="loading-logo-container">
            <Logo className="loading-logo" />
        </div>
    );
};

export default LoadingLogo;
