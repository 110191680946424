import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const Admin = () => {
    const [user] = useAuthState(auth);
    const [testimonials, setTestimonials] = useState([]);
    const [blogPosts, setBlogPosts] = useState([]);
    const [houses, setHouses] = useState([]);
    const [contactSubmissions, setContactSubmissions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch testimonials
                const testimonialsSnapshot = await getDocs(collection(db, 'testimonials'));
                const testimonialsData = testimonialsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setTestimonials(testimonialsData);

                // Fetch houses
                const housesSnapshot = await getDocs(collection(db, 'ourHomes'));
                const housesData = housesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setHouses(housesData);

                // Fetch blog posts
                const blogPostsSnapshot = await getDocs(collection(db, 'blogPosts'));
                const blogPostsData = blogPostsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setBlogPosts(blogPostsData);

                // Fetch contact submissions
                const submissionsSnapshot = await getDocs(collection(db, 'contactSubmissions'));
                const submissionsData = submissionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setContactSubmissions(submissionsData);

                setLoading(false);
            } catch (err) {
                console.error('Error fetching data:', err);
                setError('Failed to load data');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleDeleteTestimonial = async (id) => {
        if (window.confirm('Are you sure you want to delete this testimonial?')) {
            try {
                await deleteDoc(doc(db, 'testimonials', id));
                setTestimonials(testimonials.filter(testimonial => testimonial.id !== id));
            } catch (err) {
                console.error('Error deleting testimonial:', err);
                setError('Failed to delete testimonial');
            }
        }
    };

    const handleDeleteHouse = async (id) => {
        if (window.confirm('Are you sure you want to delete this house?')) {
            try {
                await deleteDoc(doc(db, 'ourHomes', id));
                setHouses(blogPosts.filter(house => house.id !== id));
            } catch (err) {
                console.error('Error deleting house:', err);
                setError('Failed to delete house');
            }
        }
    };

    const handleDeleteBlogPost = async (id) => {
        if (window.confirm('Are you sure you want to delete this blog post?')) {
            try {
                await deleteDoc(doc(db, 'blogPosts', id));
                setBlogPosts(blogPosts.filter(post => post.id !== id));
            } catch (err) {
                console.error('Error deleting blog post:', err);
                setError('Failed to delete blog post');
            }
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!user) return <div>Please log in to access this page.</div>;

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Admin Dashboard</h1>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                <div className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-2xl font-semibold mb-4">Quick Actions</h2>
                    <div className="space-y-2">
                        <Link to="/admin/add-testimonial" className="block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors">
                            Add New Testimonial
                        </Link>
                        <Link to="/admin/add-house" className="block bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600 transition-colors">
                            Add New House
                        </Link>
                        <Link to="/admin/add-blog-post" className="block bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors">
                            Add New Blog Post
                        </Link>
                    </div>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-2xl font-semibold mb-4">Website Statistics</h2>
                    <p>Total Testimonials: {testimonials.length}</p>
                    <p>Total Blog Posts: {blogPosts.length}</p>
                    <p>Total Houses: {houses.length}</p>
                    <p>Total Contact Submissions: {contactSubmissions.length}</p>
                </div>
            </div>

            <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Recent Testimonials</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Author</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Content</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {testimonials.slice(0, 5).map((testimonial) => (
                                <tr key={testimonial.id}>
                                    <td className="px-6 py-4 whitespace-nowrap">{testimonial.author}</td>
                                    <td className="px-6 py-4">{testimonial.content.substring(0, 100)}...</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <button
                                            onClick={() => handleDeleteTestimonial(testimonial.id)}
                                            className="text-red-600 hover:text-red-900"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Our Homes</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Content Preview</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {houses.slice(0, 5).map((house) => (
                                <tr key={house.id}>
                                    <td className="px-6 py-4 whitespace-nowrap">{house.title}</td>
                                    <td className="px-6 py-4">
                                        <div className="max-h-20 overflow-hidden">
                                            <ReactQuill
                                                value={house.content.substring(0, 150) + '...'}
                                                readOnly={true}
                                                theme="bubble"
                                            />
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <Link to={`/admin/edit-house/${house.id}`} className="text-blue-600 hover:text-blue-900 mr-4">
                                            Edit
                                        </Link>
                                        <button
                                            onClick={() => handleDeleteHouse(house.id)}
                                            className="text-red-600 hover:text-red-900"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Recent Blog Posts</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Content Preview</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {blogPosts.slice(0, 5).map((post) => (
                                <tr key={post.id}>
                                    <td className="px-6 py-4 whitespace-nowrap">{post.title}</td>
                                    <td className="px-6 py-4">
                                        <div className="max-h-20 overflow-hidden">
                                            <ReactQuill
                                                value={post.content.substring(0, 150) + '...'}
                                                readOnly={true}
                                                theme="bubble"
                                            />
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <Link to={`/admin/edit-blog-post/${post.id}`} className="text-blue-600 hover:text-blue-900 mr-4">
                                            Edit
                                        </Link>
                                        <button
                                            onClick={() => handleDeleteBlogPost(post.id)}
                                            className="text-red-600 hover:text-red-900"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div>
                <h2 className="text-2xl font-semibold mb-4">Recent Contact Submissions</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Message</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {contactSubmissions.slice(0, 5).map((submission) => (
                                <tr key={submission.id}>
                                    <td className="px-6 py-4 whitespace-nowrap">{submission.name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{submission.email}</td>
                                    <td className="px-6 py-4">{submission.message.substring(0, 100)}...</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {submission.timestamp?.toDate().toLocaleDateString() || 'N/A'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Admin;