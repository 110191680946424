import { Helmet } from 'react-helmet';

const Seo = ({ title, description, keywords, canonicalUrl, ogType, ogTitle, ogDescription, ogImage, ogUrl }) => {
    return (
        <Helmet>
            {/* General SEO tags */}
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <link rel="canonical" href={canonicalUrl} />

            {/* Open Graph tags for social media */}
            <meta property="og:type" content={ogType} />
            <meta property="og:title" content={ogTitle} />
            <meta property="og:description" content={ogDescription} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:url" content={ogUrl} />

            {/* Safari Tinting */}
            <meta name="theme-color" content="#008080" />
        </Helmet>
    );
};

export default Seo;