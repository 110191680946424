import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { motion } from 'framer-motion';
import { db } from '../firebase';
import Seo from '../components/Seo';
import HeroImage from '../assets/images/hero.jpeg';
import LoadingLogo from '../components/LoadingLogo';

/**
 * Generates a URL-friendly slug from a given title.
 * @param {string} title - The title to convert into a slug.
 * @returns {string} The generated slug.
 */
const generateSlug = (title) => {
    return title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)/g, '');
};

/**
 * OurHomes component displays a list of care homes offered by Sandbourne Care.
 * It fetches home data from Firestore and presents it in a grid layout with animations.
 * 
 * @component
 * @returns {JSX.Element} The rendered OurHomes component
 */
const OurHomes = () => {
    const [homes, setHomes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHomes = async () => {
            try {
                const q = query(collection(db, 'ourHomes'), orderBy('title', 'desc'));
                const querySnapshot = await getDocs(q);
                const fetchedHomes = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setHomes(fetchedHomes);
            } catch (err) {
                console.error('Error fetching homes:', err);
                setError('Failed to load homes. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchHomes();
    }, []);

    if (loading) {
        return <LoadingLogo />;
    }
    if (error) return <div>{error}</div>;
    if (homes.length === 0) return <div>No homes available at the moment.</div>;

    return (
        <motion.div
            className="container mx-auto px-4 py-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <Seo
                title="Our Homes | Sandbourne Care"
                description="Explore the range of residential homes offered by Sandbourne Care. Each home provides a safe, comfortable, and supportive environment tailored to individual needs."
                keywords="Sandbourne Care, residential homes, care services, supported living, Bournemouth"
                canonicalUrl="https://www.sandbournecare.co.uk/our-homes"
                ogType="website"
                ogTitle="Our Homes | Sandbourne Care"
                ogDescription="Discover the residential homes at Sandbourne Care, offering tailored care and support in a safe and welcoming environment."
                ogImage={HeroImage}
                ogUrl="https://www.sandbournecare.co.uk/our-homes"
            />

            <motion.h1
                className="text-3xl font-bold mb-6"
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5 }}
            >
                Our Homes
            </motion.h1>
            <motion.div
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
                variants={{
                    hidden: { opacity: 0 },
                    show: {
                        opacity: 1,
                        transition: {
                            staggerChildren: 0.1
                        }
                    }
                }}
                initial="hidden"
                animate="show"
            >
                {homes
                    .sort((a, b) => {
                        if (a.title === "Opening Soon") return 1
                        if (b.title === "Opening Soon") return -1
                        return 0
                    })
                    .map(home => (
                        <motion.div
                            key={home.id}
                            className="bg-white rounded-lg shadow-md overflow-hidden"
                            variants={{
                                hidden: { opacity: 0, y: 20 },
                                show: { opacity: 1, y: 0 }
                            }}
                        >
                            <img
                                src={home.coverImage || 'https://via.placeholder.com/400x200'}
                                alt={home.title}
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-4">
                                <h2 className="text-xl font-semibold mb-2">{home.title}</h2>
                                <p className="text-gray-600 mb-4">{home.summary}</p>
                                <Link to={`/our-homes/${generateSlug(home.title)}`} className="text-teal-600 hover:text-teal-700">
                                    Read more
                                </Link>
                            </div>
                        </motion.div>
                    ))}
            </motion.div>
        </motion.div>
    );
};

export default OurHomes;