import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

const AddTestimonial = () => {
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
    const [testimonial, setTestimonial] = useState({
        author: '',
        content: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTestimonial(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);

        try {
            // Add the testimonial directly to Firestore
            const docRef = await addDoc(collection(db, 'testimonials'), {
                ...testimonial,
                createdAt: new Date()
            });
            console.log("Document written with ID: ", docRef.id);
            navigate('/admin');
        } catch (err) {
            console.error("Error adding document: ", err);
            setError(err.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!user) {
        return <div>Please log in to access this page.</div>;
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Add New Testimonial</h1>
            <form onSubmit={handleSubmit} className="max-w-lg">
                <div className="mb-4">
                    <label htmlFor="author" className="block mb-2">Author Name</label>
                    <input
                        type="text"
                        id="author"
                        name="author"
                        value={testimonial.author}
                        onChange={handleChange}
                        required
                        className="w-full px-3 py-2 border rounded-md"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="content" className="block mb-2">Testimonial Content</label>
                    <textarea
                        id="content"
                        name="content"
                        value={testimonial.content}
                        onChange={handleChange}
                        required
                        rows="4"
                        className="w-full px-3 py-2 border rounded-md"
                    ></textarea>
                </div>
                {error && <div className="text-red-500 mb-4">{error}</div>}
                <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 disabled:bg-blue-300"
                >
                    {isSubmitting ? 'Adding...' : 'Add Testimonial'}
                </button>
            </form>
        </div>
    );
};

export default AddTestimonial;