import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Use of Cookies</h2>
                <p>
                    Our website uses cookies to enhance your browsing experience and provide personalized service.
                    Cookies are small text files that are placed on your computer by websites that you visit.
                    They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site.
                </p>
            </section>
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Types of Cookies We Use</h2>
                <ul className="list-disc pl-5">
                    <li>
                        <strong>Essential cookies:</strong> These are cookies that are required for the operation of our website.
                        They include, for example, cookies that enable you to log into secure areas of our website.
                    </li>
                    <li>
                        <strong>Analytical/performance cookies:</strong> These allow us to recognize and count the number of visitors and to see how visitors move around our website when they are using it.
                        This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.
                    </li>
                    <li>
                        <strong>Marketing cookies:</strong> These are used to recognize you when you return to our website.
                        This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
                    </li>
                </ul>
            </section>
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Your Choices Regarding Cookies</h2>
                <p>
                    When you first visit our website, you will be presented with a cookie banner that allows you to accept or reject optional cookies.
                    Essential cookies cannot be rejected as they are strictly necessary to provide you with services.
                </p>
                <p>
                    You can change your cookie preferences at any time by clicking on the 'Cookie Settings' link in the footer of our website.
                </p>
                <p>
                    You can also set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies.
                    If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly.
                </p>
            </section>
            {/* Add more sections as needed */}
        </div>
    );
};

export default PrivacyPolicy;