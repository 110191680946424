import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { X, CheckSquare, Square } from 'lucide-react';
import { setCookies } from '../utils/cookieUtils';

/**
 * CookieConsent component for managing user cookie preferences.
 * @returns {React.Component} A cookie consent banner or null if consent is already given.
 */
const CookieConsent = () => {
    const [showBanner, setShowBanner] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [cookiePreferences, setCookiePreferences] = useState({
        essential: true,
        analytics: false,
        marketing: false
    });

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setShowBanner(true);
        } else {
            setCookiePreferences(JSON.parse(consent));
        }
    }, []);

    /**
     * Handles accepting all cookie types.
     */
    const handleAcceptAll = () => {
        const allAccepted = { essential: true, analytics: true, marketing: true };
        localStorage.setItem('cookieConsent', JSON.stringify(allAccepted));
        setCookiePreferences(allAccepted);
        setCookies(allAccepted);
        setShowBanner(false);
    };

    /**
     * Saves current cookie preferences to localStorage.
     */
    const handleSavePreferences = () => {
        localStorage.setItem('cookieConsent', JSON.stringify(cookiePreferences));
        setCookies(cookiePreferences);
        setShowBanner(false);
    };

    /**
     * Toggles a specific cookie type preference.
     * @param {string} cookieType - The type of cookie to toggle.
     */
    const handleToggle = (cookieType) => {
        if (cookieType !== 'essential') {
            setCookiePreferences(prev => ({ ...prev, [cookieType]: !prev[cookieType] }));
        }
    };

    if (!showBanner) return null;

    return (
        <div className="fixed bottom-4 left-4 right-4 bg-white p-6 shadow-lg border-2 border-teal-600 z-50 rounded-lg max-w-4xl mx-auto">
            <div className="flex justify-between items-start mb-4">
                <h2 className="text-xl font-semibold">Cookie Settings</h2>
                <button onClick={() => setShowBanner(false)} className="text-gray-500 hover:text-gray-700">
                    <X size={24} />
                </button>
            </div>
            <p className="mb-4">
                We use cookies to enhance your browsing experience and provide personalized service.
                Essential cookies are necessary for the website to function and cannot be switched off.
            </p>
            <div className="flex space-x-4 mb-4">
                <button
                    onClick={handleAcceptAll}
                    className="bg-teal-600 text-white px-4 py-2 rounded-md hover:bg-teal-700 transition-colors"
                >
                    Accept All
                </button>
                <button
                    onClick={() => setShowDetails(!showDetails)}
                    className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-300 transition-colors"
                >
                    {showDetails ? 'Hide Details' : 'Show Details'}
                </button>
            </div>
            {showDetails && (
                <div className="mb-4 space-y-2">
                    <div className="flex items-center">
                        <CheckSquare className="text-teal-600 mr-2" size={20} />
                        <span className="font-semibold">Essential (Required)</span>
                    </div>
                    {['analytics', 'marketing'].map(type => (
                        <div key={type} className="flex items-center">
                            <button onClick={() => handleToggle(type)} className="mr-2">
                                {cookiePreferences[type] ? <CheckSquare className="text-teal-600" size={20} /> : <Square className="text-gray-400" size={20} />}
                            </button>
                            <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
                        </div>
                    ))}
                </div>
            )}
            <div className="flex justify-between items-center">
                <p className="text-sm text-gray-600">
                    For more information, please read our <Link to="/privacy-policy" className="text-teal-600 hover:underline">Privacy Policy</Link>.
                </p>
                {showDetails && (
                    <button
                        onClick={handleSavePreferences}
                        className="bg-teal-600 text-white px-4 py-2 rounded-md hover:bg-teal-700 transition-colors"
                    >
                        Save Preferences
                    </button>
                )}
            </div>
        </div>
    );
};

export default CookieConsent;