import React from 'react';
import { Clock, Users, Heart, Target, Award } from 'lucide-react';
import { motion } from 'framer-motion';

// Import components
import Seo from '../components/Seo';

// Import assets
import storyImage from '../assets/images/story.jpeg';
import qualityImage from '../assets/images/quality.jpeg';

/**
 * ValueCard component displays a card with an icon, title, and description.
 * Used to represent core values in the About page.
 *
 * @component
 * @param {Object} props - The props object.
 * @param {React.Component} props.icon - The Lucide icon to display.
 * @param {string} props.title - The title of the value.
 * @param {string} props.description - The description of the value.
 * @returns {JSX.Element} The rendered value card component.
 */
const ValueCard = ({ icon: Icon, title, description }) => (
    <motion.div
        className="bg-white p-6 rounded-lg shadow-md"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
    >
        <Icon className="w-12 h-12 text-teal-600 mb-4" />
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
    </motion.div>
);

/**
 * About component displays the "About Us" page for Sandbourne Care.
 * It includes sections detailing the company's story, core values, commitment to quality, and a call to action for joining the team.
 * The component now includes animations for a more engaging user experience.
 *
 * @component
 * @returns {JSX.Element} The rendered About page component.
 */
const About = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            {/* SEO Component for optimizing the About page */}
            <Seo
                title="About Sandbourne Care | Dedicated to Compassionate Care"
                description="Learn about Sandbourne Care's history, values, and commitment to providing high-quality, person-centred care across the UK."
                keywords="Sandbourne Care, about us, compassionate care, quality care, care provider"
                canonicalUrl="https://www.sandbournecare.co.uk/about"
                ogType="website"
                ogTitle="About Sandbourne Care"
                ogDescription="Discover our journey, values, and commitment to excellence in providing care services."
                ogImage={storyImage}
                ogUrl="https://www.sandbournecare.co.uk/about"
            />

            {/* Hero Section: Introduction to the company */}
            <motion.section
                className="bg-gradient-to-r from-teal-600 to-teal-400 text-white py-20 px-4"
                initial={{ y: -50 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <div className="container mx-auto max-w-4xl text-center">
                    <h1 className="text-4xl md:text-5xl font-bold mb-6">About Sandbourne Care</h1>
                    <p className="text-xl md:text-2xl">
                        Dedicated to enhancing lives through compassionate care and support.
                    </p>
                </div>
            </motion.section>

            {/* Our Story Section: Overview of the company's history and evolution */}
            <motion.section
                className="py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-6xl">
                    <div className="flex flex-col md:flex-row items-center">
                        <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
                            <h2 className="text-3xl font-bold mb-4">Our Story</h2>
                            <p className="text-lg text-gray-700 mb-4">
                                Sandbourne Care was established in 2000 with a vision to deliver high-quality, person-centred care to individuals with diverse needs. Our journey started with a small team of dedicated professionals and has since grown into a trusted care provider across the UK.
                            </p>
                            <p className="text-lg text-gray-700 mb-4">
                                Over the years, we have expanded our services to include autism support, physical disability care, and programmes for adults with learning disabilities. Our growth has been driven by our commitment to excellence and our passion for enhancing the lives of those we serve.
                            </p>
                            <p className="text-lg text-gray-700">
                                Today, Sandbourne Care is recognised for its innovative approaches to care, its highly trained staff, and its warm, welcoming environments. We continue to evolve and improve, always keeping the needs and well-being of our clients at the heart of everything we do.
                            </p>
                        </div>
                        <div className="md:w-1/2">
                            <img src={storyImage} alt="Our story" className="rounded-lg shadow-lg" />
                        </div>
                    </div>
                </div>
            </motion.section>

            {/* Our Values Section: Displays the core values of the company using ValueCard components */}
            <motion.section
                className="py-16 px-4 bg-gray-100"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-6xl">
                    <h2 className="text-3xl font-bold mb-12 text-center">Our Values</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        <ValueCard
                            icon={Heart}
                            title="Compassion"
                            description="We approach every interaction with empathy and understanding."
                        />
                        <ValueCard
                            icon={Users}
                            title="Dignity"
                            description="We respect the inherent worth and dignity of every individual."
                        />
                        <ValueCard
                            icon={Target}
                            title="Excellence"
                            description="We strive for the highest standards in all aspects of our care."
                        />
                        <ValueCard
                            icon={Clock}
                            title="Reliability"
                            description="We are dependable and consistent in our care and support."
                        />
                    </div>
                </div>
            </motion.section>

            {/* Commitment to Quality Section: Details the company's commitment to providing high-quality care */}
            <motion.section
                className="py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-6xl">
                    <div className="flex flex-col md:flex-row-reverse items-center">
                        <div className="md:w-1/2 mb-8 md:mb-0 md:pl-8">
                            <h2 className="text-3xl font-bold mb-4">Our Commitment to Quality</h2>
                            <p className="text-lg text-gray-700 mb-4">
                                At Sandbourne Care, we are committed to providing the highest quality of care. This commitment is reflected in every aspect of our operations, from our rigorous staff training programs to our state-of-the-art facilities.
                            </p>
                            <ul className="space-y-2 text-lg text-gray-700">
                                <li className="flex items-start">
                                    <Award className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>CQC registered and compliant</span>
                                </li>
                                <li className="flex items-start">
                                    <Award className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Regular staff training and development</span>
                                </li>
                                <li className="flex items-start">
                                    <Award className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Person-centered care approach</span>
                                </li>
                                <li className="flex items-start">
                                    <Award className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Continuous improvement based on feedback</span>
                                </li>
                            </ul>
                        </div>
                        <div className="md:w-1/2">
                            <img src={qualityImage} alt="Quality commitment" className="rounded-lg shadow-lg" />
                        </div>
                    </div>
                </div>
            </motion.section>

            {/* Join Our Team Section: Encourages users to explore career opportunities */}
            <motion.section
                className="bg-teal-600 text-white py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-4xl text-center">
                    <h2 className="text-3xl font-bold mb-8">Join Our Team</h2>
                    <p className="text-xl mb-8">
                        We're always looking for passionate individuals to join our team. If you're committed to making a difference in people's lives, we'd love to hear from you.
                    </p>
                    <a href="/careers" className="bg-white text-teal-600 font-semibold px-6 py-3 rounded-full hover:bg-teal-50 transition-colors inline-flex items-center">
                        View Career Opportunities
                    </a>
                </div>
            </motion.section>
        </motion.div>
    );
};

export default About;