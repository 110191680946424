import React from 'react';
import { Home, Users, Heart, Clock, Shield, Coffee, Utensils, Truck, MessageCircle, UserSquare } from 'lucide-react';
import { motion } from 'framer-motion';
import Seo from '../components/Seo';
import FeatureCard from '../components/FeatureCard';

import hero from '../assets/images/why.jpeg';
import livingImage from '../assets/images/quality.jpeg';

/**
 * The ResidentialCare component renders a comprehensive page for Residential Care services.
 * It includes various sections that detail the services provided, the living environment, 
 * the available amenities, and safety measures for residents. The component now includes
 * animations for a more engaging user experience.
 *
 * @component
 * @example
 * return (
 *   <ResidentialCare />
 * )
 */
const ResidentialCare = () => {
    const features = [
        {
            icon: Home,
            title: "Home-like Environment",
            description: "Our facilities are designed to provide a comfortable, safe, and homely atmosphere for all residents."
        },
        {
            icon: Users,
            title: "Personalised Care",
            description: "We develop individualised care plans tailored to each resident's unique needs and preferences."
        },
        {
            icon: Heart,
            title: "Compassionate Staff",
            description: "Our team of dedicated professionals provides care with empathy, respect, and understanding."
        },
        {
            icon: Clock,
            title: "24/7 Support",
            description: "Round-the-clock care ensures that assistance is always available when needed."
        }
    ];

    return (
        <motion.div
            className="animate-fadeIn"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <Seo
                title="Residential Care Services | Sandbourne Care"
                description="Providing a safe, comfortable, and supportive home for those who need round-the-clock care at Sandbourne Care. Explore our Residential Care services."
                keywords="Residential Care, Sandbourne Care, care services, 24/7 support, compassionate staff"
                canonicalUrl="https://www.sandbournecare.co.uk/residential-care"
                ogType="website"
                ogTitle="Residential Care Services | Sandbourne Care"
                ogDescription="Discover how Sandbourne Care offers a safe and supportive environment with personalized care and 24/7 support in our Residential Care services."
                ogImage={hero}
                ogUrl="https://www.sandbournecare.co.uk/residential-care"
            />

            <motion.section
                className="relative bg-gradient-to-r from-teal-600 to-teal-400 text-white py-20 px-4"
                initial={{ y: -50 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <div className="container mx-auto max-w-6xl flex flex-col md:flex-row items-center">
                    <div className="md:w-1/2 mb-8 md:mb-0">
                        <h1 className="text-4xl md:text-5xl font-bold mb-6">Residential Care Services</h1>
                        <p className="text-xl md:text-2xl mb-8">
                            Providing a safe, comfortable, and supportive home for those who need round-the-clock care.
                        </p>
                    </div>
                    <div className="md:w-1/2 relative">
                        <img src={hero} alt="Sandbourne Care residential facility" className="rounded-lg shadow-lg" />
                        <div className="absolute inset-0 bg-teal-600 opacity-20 rounded-lg"></div>
                    </div>
                </div>
            </motion.section>

            <motion.section
                className="py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-6xl">
                    <h2 className="text-3xl font-bold mb-12 text-center">Our Approach to Residential Care</h2>
                    <motion.div
                        className="grid grid-cols-1 md:grid-cols-2 gap-8"
                        variants={{
                            hidden: { opacity: 0 },
                            show: {
                                opacity: 1,
                                transition: {
                                    staggerChildren: 0.2
                                }
                            }
                        }}
                        initial="hidden"
                        animate="show"
                    >
                        {features.map((feature, index) => (
                            <motion.div
                                key={index}
                                variants={{
                                    hidden: { opacity: 0, y: 20 },
                                    show: { opacity: 1, y: 0 }
                                }}
                            >
                                <FeatureCard {...feature} />
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </motion.section>

            <motion.section
                className="bg-gray-100 py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-6xl">
                    <div className="flex flex-col md:flex-row items-center">
                        <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
                            <h2 className="text-3xl font-bold mb-6">Life in Our Residential Care Homes</h2>
                            <p className="text-lg text-gray-700 mb-4">
                                Our residential care homes are designed to provide a balance of support, independence, and community. Residents can expect:
                            </p>
                            <ul className="space-y-2 text-lg text-gray-700">
                                <li className="flex items-start">
                                    <Home className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Private rooms that can be personalised</span>
                                </li>
                                <li className="flex items-start">
                                    <Coffee className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Communal areas for socialising and activities</span>
                                </li>
                                <li className="flex items-start">
                                    <Utensils className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Nutritious meals and special dietary accommodations</span>
                                </li>
                                <li className="flex items-start">
                                    <Truck className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Regular events and outings</span>
                                </li>
                                <li className="flex items-start">
                                    <UserSquare className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Enhancing family and social contact.</span>
                                </li>
                            </ul>
                        </div>
                        <div className="md:w-1/2">
                            <img src={livingImage} alt="Residents enjoying communal area" className="rounded-lg shadow-lg" />
                        </div>
                    </div>
                </div>
            </motion.section>

            <motion.section
                className="py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-6xl">
                    <h2 className="text-3xl font-bold mb-8 text-center">Our Residential Care Services</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold mb-4">Personal Care</h3>
                            <ul className="space-y-2 text-gray-700">
                                <li>• Assistance with daily living activities</li>
                                <li>• Medication management</li>
                                <li>• Continence care</li>
                                <li>• Mobility support</li>
                            </ul>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold mb-4">Health & Wellbeing</h3>
                            <ul className="space-y-2 text-gray-700">
                                <li>• Regular health check-ups</li>
                                <li>• Access to healthcare professionals</li>
                                <li>• Physical therapy and exercise programmes</li>
                                <li>• Mental health support</li>
                            </ul>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold mb-4">Social Activities</h3>
                            <ul className="space-y-2 text-gray-700">
                                <li>• Group activities and events</li>
                                <li>• Hobby and interest groups</li>
                                <li>• Community outings</li>
                                <li>• Visitors and family involvement</li>
                            </ul>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold mb-4">Amenities</h3>
                            <ul className="space-y-2 text-gray-700">
                                <li>• Comfortable, home-like living spaces</li>
                                <li>• Beautiful gardens and outdoor areas</li>
                                <li>• Nutritious, chef-prepared meals</li>
                                <li>• Laundry and housekeeping services</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </motion.section>

            <motion.section
                className="bg-teal-50 py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-4xl text-center">
                    <h2 className="text-3xl font-bold mb-6">Ensuring Safety and Well-being</h2>
                    <p className="text-lg text-gray-700 mb-8">
                        The safety and well-being of our residents is our top priority. Our residential care homes feature:
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                        <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
                            <Shield className="w-8 h-8 text-teal-600 mr-4" />
                            <span>24/7 staff availability</span>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
                            <Shield className="w-8 h-8 text-teal-600 mr-4" />
                            <span>Emergency call systems</span>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
                            <Shield className="w-8 h-8 text-teal-600 mr-4" />
                            <span>Regular health and safety assessments</span>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
                            <Shield className="w-8 h-8 text-teal-600 mr-4" />
                            <span>Strict hygiene and infection control measures</span>
                        </div>
                    </div>
                </div>
            </motion.section>

            <motion.section
                className="py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-4xl text-center">
                    <h2 className="text-3xl font-bold mb-6">Learn More About Our Residential Care Services</h2>
                    <p className="text-lg text-gray-700 mb-8">
                        If you're considering residential care for yourself or a loved one, we're here to help. Our team would be happy to answer your questions, arrange a visit, or discuss how we can meet your specific care needs.
                    </p>
                    <a href="/contact" className="btn-primary inline-flex items-center">
                        Contact Us Today <MessageCircle className="ml-2 w-5 h-5" />
                    </a>
                </div>
            </motion.section>
        </motion.div>
    );
};

export default ResidentialCare;