/**
 * Contact.js
 * This component renders the Contact page of the Sandbourne Care website.
 * It includes a contact form, contact information, and a Google Maps embed.
 */

import React, { useState, useCallback } from 'react';
import { MapPin, Phone, Mail, Clock, Send } from 'lucide-react';
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import Seo from '../components/Seo';
import HeroImage from '../assets/images/hero.jpeg';
import { motion } from 'framer-motion';

/**
 * ContactInfo component
 * Renders a single piece of contact information with an icon.
 * 
 * @param {Object} props - The component props
 * @param {React.ComponentType} props.icon - The icon component to display
 * @param {string} props.title - The title of the contact information
 * @param {string} props.content - The content of the contact information
 * @param {string} props.type - The type of contact information (phone, email, address, or default)
 */
const ContactInfo = React.memo(({ icon: Icon, title, content, type }) => {
    const renderContent = useCallback(() => {
        switch (type) {
            case 'phone':
                return <a href={`tel:${content}`} className="text-teal-600">{content}</a>;
            case 'email':
                return <a href={`mailto:${content}`} className="text-teal-600">{content}</a>;
            case 'address':
                return <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(content)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-teal-600">{content}</a>;
            default:
                return <span>{content}</span>;
        }
    }, [content, type]);

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex items-start mb-4"
        >
            <Icon className="w-6 h-6 text-teal-600 mr-4 flex-shrink-0 mt-1" />
            <div>
                <h3 className="font-semibold">{title}</h3>
                <p className="text-gray-600">{renderContent()}</p>
            </div>
        </motion.div>
    );
});

/**
 * Contact component
 * Renders the main Contact page, including the contact form and contact information.
 */
const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitMessage, setSubmitMessage] = useState('');

    /**
     * Handles changes in the form inputs
     * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} e - The change event
     */
    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    }, []);

    /**
     * Handles form submission
     * @param {React.FormEvent<HTMLFormElement>} e - The form submit event
     */
    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmitMessage('');

        try {
            await addDoc(collection(db, 'contactSubmissions'), {
                ...formData,
                phone: formData.phone || 'Not provided',
                timestamp: serverTimestamp()
            });

            setSubmitMessage('Thank you for your message. We will get back to you soon!');
            setFormData({ name: '', email: '', phone: '', message: '' });
        } catch (error) {
            console.error('Error submitting form:', error);
            setSubmitMessage('There was an error sending your message. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    }, [formData]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="animate-fadeIn"
        >
            <Seo
                title="Contact Us | Sandbourne Care"
                description="Get in touch with Sandbourne Care for any queries or assistance. We're here to help."
                keywords="Sandbourne Care, contact, support, care services"
                canonicalUrl="https://www.sandbournecare.co.uk/contact"
                ogType="website"
                ogTitle="Contact Us | Sandbourne Care"
                ogDescription="Reach out to Sandbourne Care for support or inquiries. We are always here to assist you."
                ogImage={HeroImage}
                ogUrl="https://www.sandbournecare.co.uk/contact"
            />

            <motion.section
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="bg-gradient-to-r from-teal-600 to-teal-400 text-white py-20 px-4"
            >
                <div className="container mx-auto max-w-4xl text-center">
                    <h1 className="text-4xl md:text-5xl font-bold mb-6">Contact Us</h1>
                    <p className="text-xl md:text-2xl">
                        We're here to help. Get in touch with us today.
                    </p>
                </div>
            </motion.section>

            <section className="py-16 px-4">
                <div className="container mx-auto max-w-6xl">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                        <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <h2 className="text-3xl font-bold mb-6">Get in Touch</h2>
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: 0.6 }}
                                >
                                    <label htmlFor="name" className="block mb-1 font-medium">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                                    />
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: 0.7 }}
                                >
                                    <label htmlFor="email" className="block mb-1 font-medium">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                                    />
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: 0.8 }}
                                >
                                    <label htmlFor="phone" className="block mb-1 font-medium">Phone</label>
                                    <input
                                        type="tel"
                                        id="phone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                                    />
                                </motion.div>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: 0.9 }}
                                >
                                    <label htmlFor="message" className="block mb-1 font-medium">Message</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                        rows="4"
                                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                                    ></textarea>
                                </motion.div>
                                <motion.button
                                    type="submit"
                                    className="bg-teal-600 text-white font-semibold px-6 py-3 rounded-md hover:bg-teal-700 transition-colors flex items-center"
                                    disabled={isSubmitting}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    {isSubmitting ? 'Sending...' : 'Send Message'}
                                    <Send className="ml-2 w-4 h-4" />
                                </motion.button>
                                {submitMessage && (
                                    <motion.p
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.5 }}
                                        className={`mt-4 ${submitMessage.includes('error') ? 'text-red-600' : 'text-green-600'}`}
                                    >
                                        {submitMessage}
                                    </motion.p>
                                )}
                            </form>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, x: 20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <h2 className="text-3xl font-bold mb-6">Contact Information</h2>
                            <div className="mb-8">
                                <ContactInfo
                                    icon={MapPin}
                                    title="Address"
                                    content="Sandbourne Care, 39 Portchester Road, Bournemouth, Dorset BH8 8JU"
                                    type="address"
                                />
                                <ContactInfo
                                    icon={Phone}
                                    title="Phone"
                                    content="01202 555048"
                                    type="phone"
                                />
                                <ContactInfo
                                    icon={Mail}
                                    title="Email"
                                    content="jose@sandbournecare.co.uk"
                                    type="email"
                                />
                                <ContactInfo
                                    icon={Clock}
                                    title="Office Hours"
                                    content="Monday - Friday: 9am - 5pm"
                                />
                            </div>
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: 0.6 }}
                                className="relative w-full h-0 pb-[56.25%]"
                            >
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2525.4031961663763!2d-1.8715122095947976!3d50.731010294026525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4873a1e14b877cc3%3A0xf439fb6dc993ff32!2s39%20Portchester%20Rd%2C%20Bournemouth%20BH8%208JU!5e0!3m2!1sen!2suk!4v1724522446653!5m2!1sen!2suk"
                                    width="600"
                                    height="450"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    className="absolute top-0 left-0 w-full h-full rounded-lg shadow-md"
                                    title="Map showing Sandbourne Care location"
                                ></iframe>
                            </motion.div>
                        </motion.div>
                    </div>
                </div>
            </section>
        </motion.div>
    );
};

export default React.memo(Contact);
