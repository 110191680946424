import React from 'react';
import { Link } from 'react-router-dom';

// Import assets
import logo from '../assets/images/logo.svg';
//import CQCWidget from './CQCWidget';

/**
 * Footer component that displays the site's footer section, including company information,
 * links to services, social media icons, and legal links such as the privacy policy and cookie settings.
 *
 * @component
 */
const Footer = () => {
    /**
     * Handles the opening of cookie settings by removing the cookie consent from localStorage
     * and reloading the page to reflect the changes.
     */
    const handleOpenCookieSettings = () => {
        localStorage.removeItem('cookieConsent');
        window.location.reload();
    };

    return (
        <footer className="bg-teal-800 text-white py-12">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20">
                    {/* About Section */}
                    <div>
                        <div className='flex text-2xl font-sans mb-4'>
                            <img src={logo} alt="Sandbourne Care Logo" className="h-8 w-8 mr-2" />
                            Sandbourne Care
                        </div>

                        <p className="mb-4">
                            Providing compassionate care and support services for those in need across the UK.
                        </p>
                    </div>

                    {/* Our Services Section */}
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Our Services</h3>
                        <ul className="space-y-2 mb-8">
                            <li><Link to="/services/residential-care" className="hover:text-teal-300 transition-colors">Residential Care</Link></li>
                            <li><Link to="/services/supported-living" className="hover:text-teal-300 transition-colors">Supported Living</Link></li>
                            <li><Link to="/services/respite-care" className="hover:text-teal-300 transition-colors">Respite Care</Link></li>
                        </ul>
                    </div>


                    {/* Support Services Section */}
                    <div>
                        <h3 className="text-xl font-semibold mb-4">Support Services</h3>
                        <ul className="space-y-2 mb-8">
                            {/* <li><Link to="/support/mental-health" className="hover:text-teal-300 transition-colors">Mental Health Support</Link></li> */}
                            <li><Link to="/support/autism" className="hover:text-teal-300 transition-colors">Autism Support</Link></li>
                            <li><Link to="/support/physical-disability" className="hover:text-teal-300 transition-colors">Physical Disability Support</Link></li>
                            <li><Link to="/support/learning-disability" className="hover:text-teal-300 transition-colors">Learning Disability Support</Link></li>
                        </ul>
                    </div>

                    {/* CQC Widget Section */}
                    <div id='cqc-widget-container'>
                        {/* <CQCWidget className='rounded-lg' /> */}
                    </div>

                </div>

                {/* Legal Links and Copyright */}
                <div className="mt-8 pt-8 border-t border-gray-700 text-center">
                    <p className="mb-2">Sandbourne Care, 39 Portchester Road, Bournemouth, England, BH8 8JU</p>
                    <p>&copy; {new Date().getFullYear()} Sandbourne Care. All rights reserved.</p>
                    <div className="mt-4 space-x-4">
                        <Link to="/privacy-policy" className="text-sm text-gray-400 hover:text-white">Privacy Policy</Link>
                        <button onClick={handleOpenCookieSettings} className="text-sm text-gray-400 hover:text-white">
                            Cookie Settings
                        </button>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
