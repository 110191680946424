import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialSlider = () => {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        const fetchTestimonials = async () => {
            const querySnapshot = await getDocs(collection(db, 'testimonials'));
            const testimonialsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setTestimonials(testimonialsData);
        };

        fetchTestimonials();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    return (
        <div className="bg-gray-100 py-12">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-8">What Our Clients Say</h2>
                <Slider {...settings}>
                    {testimonials.map((testimonial) => (
                        <div key={testimonial.id} className="px-4">
                            <div className="bg-white p-6 rounded-lg shadow-md">
                                <p className="text-gray-600 mb-4">{testimonial.content}</p>
                                <p className="font-semibold text-right">- {testimonial.author}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default TestimonialSlider;