import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../firebase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import Seo from '../components/Seo';  // Import the Seo component
import LoadingLogo from '../components/LoadingLogo';

const generateSlug = (title) => {
    return title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-') // Replace spaces and non-alphanumeric characters with hyphens
        .replace(/(^-|-$)/g, '');    // Remove leading and trailing hyphens
};

const House = () => {
    const [house, setHouse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { slug } = useParams(); // Use slug instead of id

    useEffect(() => {
        const fetchHouseByTitle = async () => {
            try {
                const allHomesSnapshot = await getDocs(collection(db, 'ourHomes'));
                let foundHouse = null;

                allHomesSnapshot.forEach((doc) => {
                    const houseData = doc.data();
                    if (generateSlug(houseData.title) === slug) {
                        foundHouse = { id: doc.id, ...houseData };
                    }
                });

                if (foundHouse) {
                    setHouse(foundHouse);
                } else {
                    console.log('No such document!');
                }
            } catch (err) {
                console.error('Error fetching document:', err);
                setError('An error occurred while fetching the document.');
            }
            setLoading(false);
        };

        fetchHouseByTitle();
    }, [slug]);
    if (loading) {
        return <LoadingLogo />;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!house) {
        return <div>House not found</div>;
    }

    return (
        <div className="container mx-auto px-4 py-8">
            {/* SEO Component for optimizing the House page */}
            <Seo
                title={house.title}
                description={house.description || `Learn more about ${house.title} at Sandbourne Care.`}
                keywords={`Sandbourne Care, ${house.title}, residential care, supported living`}
                canonicalUrl={`https://www.sandbournecare.co.uk/houses/${slug}`}
                ogType="article"
                ogTitle={house.title}
                ogDescription={house.description || `Discover details about ${house.title} at Sandbourne Care.`}
                ogImage={house.coverImage || 'https://via.placeholder.com/800x400'}
                ogUrl={`https://www.sandbournecare.co.uk/houses/${slug}`}
            />

            <h1 className="text-3xl font-bold mb-6">{house.title}</h1>
            <div className="flex flex-col lg:flex-row gap-8">
                {/* Left Column - Content */}
                <div className="lg:w-1/2">
                    <img
                        src={house.coverImage || 'https://via.placeholder.com/800x400'}
                        alt={`Cover of ${house.title}`}
                        className="w-full h-64 object-cover rounded-lg mb-6"
                    />
                    <div className="prose max-w-none text-xl">
                        <ReactQuill
                            value={house.content}
                            readOnly={true}
                            theme="bubble"
                        />
                    </div>
                </div>

                {/* Right Column - Gallery */}
                <div className="lg:w-1/2">
                    <div className="masonry-layout">
                        {house.gallery && house.gallery.length > 0 ? (
                            house.gallery.map((url, index) => (
                                <img
                                    key={index}
                                    src={url}
                                    alt={`Gallery ${index + 1}`}
                                    className="w-full object-cover mb-4 rounded-md"
                                />
                            ))
                        ) : (
                            <p>No gallery images available.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default House;