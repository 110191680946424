import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, orderBy, query, limit } from 'firebase/firestore';
import { db } from '../firebase';

// Import components
import Seo from '../components/Seo';  // Import the Seo component

// Import assets
import HeroImage from '../assets/images/hero.jpeg';
import LoadingLogo from '../components/LoadingLogo';

const BlogList = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPosts = async () => {
            const q = query(collection(db, 'blogPosts'), orderBy('createdAt', 'desc'), limit(10));
            const querySnapshot = await getDocs(q);
            const fetchedPosts = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setPosts(fetchedPosts);
            setLoading(false);
        };

        fetchPosts();
    }, []);

    if (loading) {
        return <LoadingLogo />;
    }

    return (
        <div className="container mx-auto px-4 py-8">
            {/* SEO Component for optimizing the Blog List page */}
            <Seo
                title="Our Stories | Sandbourne Care"
                description="Discover inspiring stories and insights about care services from Sandbourne Care. Read our latest blog posts covering a range of topics."
                keywords="Sandbourne Care, blog, care stories, mental health support, autism care, disability services"
                canonicalUrl="https://www.sandbournecare.co.uk/blog"
                ogType="website"
                ogTitle="Our Stories | Sandbourne Care"
                ogDescription="Stay updated with the latest stories and insights from Sandbourne Care. Learn about our approach to care and support services."
                ogImage={HeroImage}  // Replace with the actual path of the image
                ogUrl="https://www.sandbournecare.co.uk/blog"
            />

            <h1 className="text-3xl font-bold mb-6">Our Stories</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {posts.map(post => (
                    <div key={post.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                        <img src={post.imageUrl || 'https://via.placeholder.com/400x200'} alt={post.title} className="w-full h-48 object-cover" />
                        <div className="p-4">
                            <h2 className="text-xl font-semibold mb-2">{post.title}</h2>
                            <p className="text-gray-600 mb-4 line-clamp-3">{post.summary}</p>
                            <Link to={`/our-stories/${post.id}`} className="text-teal-600 hover:text-teal-700">Read more</Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogList;
