export const setCookies = (preferences) => {
    // Set essential cookies
    document.cookie = "essential=true; path=/; max-age=31536000"; // 1 year

    if (preferences.analytics) {
        document.cookie = "analytics=true; path=/; max-age=31536000";
    } else {
        document.cookie = "analytics=false; path=/; max-age=0"; // Expire immediately
    }

    if (preferences.marketing) {
        document.cookie = "marketing=true; path=/; max-age=31536000";
    } else {
        document.cookie = "marketing=false; path=/; max-age=0"; // Expire immediately
    }
};
