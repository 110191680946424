import React from 'react';
import { Activity, Home, Users, Briefcase, MessageCircle } from 'lucide-react';

// Import components
import Seo from '../components/Seo';  // Import the Seo component

// Import assets
import HeroImage from '../assets/images/hero.jpeg';

const FeatureCard = ({ icon: Icon, title, description }) => (
    <div className="bg-white p-6 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg">
        <Icon className="w-12 h-12 text-teal-600 mb-4" />
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
    </div>
);

const PhysicalDisabilitySupport = () => {
    const features = [
        {
            icon: Activity,
            title: "Personalized Care Plans",
            description: "We develop individualized care plans tailored to each person's specific needs and goals."
        },
        {
            icon: Home,
            title: "Home Adaptation Support",
            description: "We assist in making homes more accessible and comfortable for individuals with physical disabilities."
        },
        {
            icon: Users,
            title: "Community Integration",
            description: "Our programs focus on promoting independence and active participation in the community."
        },
        {
            icon: Briefcase,
            title: "Vocational Support",
            description: "We provide assistance in finding and maintaining employment or volunteering opportunities."
        }
    ];

    return (
        <div className="animate-fadeIn">
            {/* SEO Component for optimizing the Physical Disability Support page */}
            <Seo
                title="Physical Disability Support Services | Sandbourne Care"
                description="Empowering individuals with physical disabilities through personalized care plans, home adaptation support, and community integration at Sandbourne Care."
                keywords="Physical Disability Support, Sandbourne Care, home adaptation, community integration, vocational support"
                canonicalUrl="https://www.sandbournecare.co.uk/physical-disability-support"
                ogType="website"
                ogTitle="Physical Disability Support Services | Sandbourne Care"
                ogDescription="Discover how Sandbourne Care supports individuals with physical disabilities, offering personalized care and promoting independence."
                ogImage={HeroImage}  // Replace with the actual image path if needed
                ogUrl="https://www.sandbournecare.co.uk/physical-disability-support"
            />
            <section className="bg-gradient-primary text-white py-20 px-4">
                <div className="container mx-auto max-w-4xl text-center">
                    <h1 className="text-4xl md:text-5xl font-bold mb-6">Physical Disability Support Services</h1>
                    <p className="text-xl md:text-2xl">
                        Empowering individuals with physical disabilities to live life to the fullest.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4">
                <div className="container mx-auto max-w-6xl">
                    <div className="mb-12 text-center">
                        <h2 className="text-3xl font-bold mb-4">Our Approach to Physical Disability Support</h2>
                        <p className="text-lg text-gray-700 max-w-3xl mx-auto">
                            At Sandbourne Care, we believe in promoting independence, dignity, and quality of life for individuals with physical disabilities. Our team of dedicated professionals works closely with each person to provide tailored support that meets their unique needs and aspirations.
                        </p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {features.map((feature, index) => (
                            <FeatureCard key={index} {...feature} />
                        ))}
                    </div>
                </div>
            </section>

            <section className="bg-gray-100 py-16 px-4">
                <div className="container mx-auto max-w-4xl">
                    <h2 className="text-3xl font-bold mb-8 text-center">Our Physical Disability Support Services</h2>
                    <ul className="space-y-4 text-lg text-gray-700">
                        <li className="flex items-start">
                            <span className="text-teal-600 mr-2">•</span>
                            <span>Personal care assistance (bathing, dressing, grooming)</span>
                        </li>
                        <li className="flex items-start">
                            <span className="text-teal-600 mr-2">•</span>
                            <span>Mobility assistance and transfer support</span>
                        </li>
                        <li className="flex items-start">
                            <span className="text-teal-600 mr-2">•</span>
                            <span>Physical therapy and rehabilitation services</span>
                        </li>
                        <li className="flex items-start">
                            <span className="text-teal-600 mr-2">•</span>
                            <span>Occupational therapy for daily living skills</span>
                        </li>
                        <li className="flex items-start">
                            <span className="text-teal-600 mr-2">•</span>
                            <span>Assistive technology assessment and training</span>
                        </li>
                        <li className="flex items-start">
                            <span className="text-teal-600 mr-2">•</span>
                            <span>Transportation assistance</span>
                        </li>
                        <li className="flex items-start">
                            <span className="text-teal-600 mr-2">•</span>
                            <span>Social and recreational activities</span>
                        </li>
                    </ul>
                </div>
            </section>

            <section className="py-16 px-4">
                <div className="container mx-auto max-w-4xl">
                    <h2 className="text-3xl font-bold mb-8 text-center">Promoting Independence and Well-being</h2>
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <p className="text-lg text-gray-700 mb-6">
                            Our goal is to help individuals with physical disabilities achieve greater independence and improve their overall well-being. We focus on:
                        </p>
                        <ul className="space-y-2 text-gray-700">
                            <li>• Enhancing physical abilities through targeted therapies and exercises</li>
                            <li>• Developing strategies to overcome daily challenges</li>
                            <li>• Fostering self-advocacy skills</li>
                            <li>• Promoting social connections and community involvement</li>
                            <li>• Supporting emotional well-being and mental health</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="bg-teal-50 py-16 px-4">
                <div className="container mx-auto max-w-4xl text-center">
                    <h2 className="text-3xl font-bold mb-6">Get Support for Physical Disabilities</h2>
                    <p className="text-lg text-gray-700 mb-8">
                        If you or a loved one needs support with physical disabilities, we're here to help. Our team is ready to discuss your needs and create a personalized support plan.
                    </p>
                    <a href="/contact" className="btn-primary inline-flex items-center">
                        Contact Us Today <MessageCircle className="ml-2 w-5 h-5" />
                    </a>
                </div>
            </section>
        </div>
    );
};

export default PhysicalDisabilitySupport;