import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Menu, X, ChevronDown, LogOut } from 'lucide-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import logo from '../assets/images/logo.svg';

/**
 * Header component that displays the navigation bar, including links to various pages and user authentication controls.
 * The navigation bar adapts to different screen sizes and supports dropdown menus for sections with sub-items.
 *
 * @component
 */
const Header = ({ isVisible }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);

    // Close menu and dropdowns when location (URL) changes
    useEffect(() => {
        setIsMenuOpen(false);
        setOpenDropdown(null);
    }, [location]);

    // Toggle the visibility of the mobile menu
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    // Toggle the visibility of a dropdown menu
    const toggleDropdown = (dropdown) => {
        setOpenDropdown(openDropdown === dropdown ? null : dropdown);
    };

    // Handle user logout and redirect to the home page
    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/');
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    // Navigation items, including sub-items for dropdown menus
    const navItems = [
        { name: 'About', path: '/about' },
        {
            name: 'Services',
            path: '/services',
            subItems: [
                { name: 'Residential Care', path: '/services/residential-care' },
                { name: 'Supported Living', path: '/services/supported-living' },
                { name: 'Respite Care', path: '/services/respite-care' },
            ]
        },
        { name: 'Our Homes', path: '/our-homes' },
        {
            name: 'Support',
            path: '/support',
            subItems: [
                // { name: 'Mental Health Support', path: '/support/mental-health' },
                { name: 'Autism Support', path: '/support/autism' },
                { name: 'Physical Disability Support', path: '/support/physical-disability' },
                { name: 'Learning Disability Support', path: '/support/learning-disability' },
            ]
        },
        { name: 'Our Stories', path: '/our-stories' },
        { name: 'Contact', path: '/contact' },
    ];

    /**
     * DropdownMenu component to render a dropdown menu for navigation items with sub-items.
     *
     * @param {Object} item - The navigation item containing sub-items.
     * @returns {JSX.Element} The dropdown menu component.
     */
    const DropdownMenu = ({ item }) => (
        <div className="absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50 transition-all duration-300 ease-in-out opacity-0 transform scale-95 origin-top-right group-hover:opacity-100 group-hover:scale-100">
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                {item.subItems.map((subItem) => (
                    <Link
                        key={subItem.name}
                        to={subItem.path}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-teal-100 hover:text-teal-900 transition-colors duration-200"
                        role="menuitem"
                    >
                        {subItem.name}
                    </Link>
                ))}
            </div>
        </div>
    );

    return (
        <header className={`bg-gradient-primary text-white shadow-md fixed top-0 left-0 right-0 z-50 transition-transform duration-300 ${isVisible ? 'translate-y-0' : '-translate-y-full'}`}>
            <div className="container mx-auto px-4 py-4">
                <div className="flex justify-between items-center">
                    <Link to="/" className="flex text-2xl font-sans transition-colors duration-200 hover:text-teal-200">
                        <img src={logo} alt="Sandbourne Care Logo" className="w-8 mr-2" />
                        Sandbourne Care
                    </Link>

                    {/* Mobile menu button */}
                    <button onClick={toggleMenu} className="lg:hidden transition-colors duration-200 hover:text-teal-200">
                        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>

                    {/* Desktop Navigation */}
                    <nav className="hidden lg:flex space-x-6 items-center">
                        {navItems.map((item) => (
                            item.subItems ? (
                                <div key={item.name} className="relative group">
                                    <button
                                        onClick={() => toggleDropdown(item.name)}
                                        className="flex items-center text-teal-100 hover:text-white transition-colors duration-200 group"
                                    >
                                        {item.name} <ChevronDown size={20} className="ml-1 transition-transform duration-200 group-hover:rotate-180" />
                                    </button>
                                    <DropdownMenu item={item} />
                                </div>
                            ) : (
                                <NavLink
                                    key={item.name}
                                    to={item.path}
                                    className={({ isActive }) =>
                                        isActive
                                            ? "text-white font-semibold border-b-2 border-white transition-colors duration-200"
                                            : "text-teal-100 hover:text-white transition-colors duration-200"
                                    }
                                >
                                    {item.name}
                                </NavLink>
                            )
                        ))}
                        {user && !loading && (
                            <button
                                onClick={handleLogout}
                                className="flex items-center text-teal-100 hover:text-white transition-colors duration-200"
                            >
                                <LogOut className="mr-1" size={18} />
                                Logout
                            </button>
                        )}
                    </nav>
                </div>

                {/* Mobile and Tablet Navigation */}
                <nav className={`lg:hidden mt-4 bg-teal-700 rounded-lg p-0 z-50 transition-all duration-300 ease-in-out ${isMenuOpen ? 'opacity-100 max-h-screen' : 'opacity-0 max-h-0 overflow-hidden'}`}>
                    {navItems.map((item) => (
                        item.subItems ? (
                            <div key={item.name}>
                                <button
                                    onClick={() => toggleDropdown(item.name)}
                                    className="flex items-center justify-between w-full py-2 px-4 text-teal-100 hover:bg-teal-600 hover:text-white transition-colors duration-200 rounded"
                                >
                                    {item.name}
                                    <ChevronDown size={20} className={`transition-transform duration-200 ${openDropdown === item.name ? 'rotate-180' : ''}`} />
                                </button>
                                <div className={`pl-4 mt-2 space-y-2 transition-all duration-200 ${openDropdown === item.name ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}>
                                    {item.subItems.map((subItem) => (
                                        <Link
                                            key={subItem.name}
                                            to={subItem.path}
                                            className="block py-2 px-4 text-sm text-teal-100 hover:bg-teal-600 hover:text-white transition-colors duration-200 rounded"
                                            onClick={toggleMenu}
                                        >
                                            {subItem.name}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <NavLink
                                key={item.name}
                                to={item.path}
                                className={({ isActive }) =>
                                    `block py-2 px-4 ${isActive
                                        ? "bg-teal-600 text-white"
                                        : "text-teal-100 hover:bg-teal-600 hover:text-white"
                                    } transition-colors duration-200 rounded`
                                }
                                onClick={toggleMenu}
                            >
                                {item.name}
                            </NavLink>
                        )
                    ))}
                    {user && !loading && (
                        <button
                            onClick={() => {
                                handleLogout();
                                toggleMenu();
                            }}
                            className="flex items-center w-full py-2 px-4 text-teal-100 hover:bg-teal-600 hover:text-white transition-colors duration-200 rounded"
                        >
                            <LogOut className="mr-1" size={18} />
                            Logout
                        </button>
                    )}
                </nav>
            </div>
        </header>
    );
};

export default Header;
