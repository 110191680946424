import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebase'; // Assume you have Firebase storage set up
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase storage imports
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddEditHouse = () => {
    const [house, setHouse] = useState({ title: '', content: '', summary: '', coverImage: '', gallery: [] });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [coverFile, setCoverFile] = useState(null);
    const [galleryFiles, setGalleryFiles] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPost = async () => {
            try {
                if (id) {
                    const docRef = doc(db, 'ourHomes', id);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        setHouse({ id: docSnap.id, ...docSnap.data() });
                    } else {
                        setError('Document not found');
                    }
                }
            } catch (err) {
                console.error('Error fetching document:', err);
                setError('An error occurred while fetching the document.');
            }
        };
        fetchPost();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setHouse(prev => ({ ...prev, [name]: value }));
    };

    const handleContentChange = (content) => {
        setHouse(prev => ({ ...prev, content }));
    };

    const handleCoverChange = (e) => {
        setCoverFile(e.target.files[0]);
    };

    const handleGalleryChange = (e) => {
        setGalleryFiles([...e.target.files]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            // Generate a unique folder path based on the house ID or title slug
            const houseId = id || new Date().getTime().toString(); // Use ID if editing, otherwise generate a new one

            let coverImageUrl = house.coverImage;
            if (coverFile) {
                const coverFileRef = ref(storage, `houses/${houseId}/coverImage/${coverFile.name}`);
                await uploadBytes(coverFileRef, coverFile);
                coverImageUrl = await getDownloadURL(coverFileRef);
            }

            let galleryUrls = [];
            if (galleryFiles.length > 0) {
                for (let file of galleryFiles) {
                    const fileRef = ref(storage, `houses/${houseId}/gallery/${file.name}`);
                    await uploadBytes(fileRef, file);
                    const url = await getDownloadURL(fileRef);
                    galleryUrls.push(url);
                }
            }

            const houseData = {
                ...house,
                coverImage: coverImageUrl,
                gallery: galleryUrls.length > 0 ? galleryUrls : house.gallery, // Merge existing and new gallery images
                updatedAt: new Date(),
            };

            if (id) {
                const docRef = doc(db, 'ourHomes', id);
                await updateDoc(docRef, houseData);
            } else {
                await addDoc(collection(db, 'ourHomes'), {
                    ...houseData,
                    createdAt: new Date(),
                });
            }
            navigate('/admin');
        } catch (err) {
            console.error('Error adding/editing house:', err);
            setError('An error occurred while saving the house. Please try again.');
        }

        setLoading(false);
    };

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image',
    ];

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">{id ? 'Edit' : 'Add'} House</h1>
            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                    <strong className="font-bold">Error:</strong>
                    <span className="block sm:inline"> {error}</span>
                    <span onClick={() => setError(null)} className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <title>Close</title>
                            <path d="M14.348 5.652a1 1 0 0 1 0 1.415L11.415 10l2.933 2.933a1 1 0 0 1-1.415 1.415L10 11.415l-2.933 2.933a1 1 0 0 1-1.415-1.415L8.585 10 5.652 7.067a1 1 0 1 1 1.415-1.415L10 8.585l2.933-2.933a1 1 0 0 1 1.415 0z" />
                        </svg>
                    </span>
                </div>
            )}
            <form onSubmit={handleSubmit} className="max-w-4xl">
                <div className="mb-4">
                    <label htmlFor="title" className="block mb-2 font-bold">Title</label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={house.title}
                        onChange={handleChange}
                        required
                        className="w-full px-3 py-2 border rounded-md"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="summary" className="block mb-2 font-bold">Summary</label>
                    <textarea
                        id="summary"
                        name="summary"
                        value={house.summary}
                        onChange={handleChange}
                        required
                        rows="3"
                        className="w-full px-3 py-2 border rounded-md"
                    ></textarea>
                </div>
                <div className="mb-4">
                    <label htmlFor="content" className="block mb-2 font-bold">Content</label>
                    <ReactQuill
                        value={house.content}
                        onChange={handleContentChange}
                        modules={modules}
                        formats={formats}
                        className="bg-white"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="coverImage" className="block mb-2 font-bold">Cover Image</label>
                    <input
                        type="file"
                        id="coverImage"
                        name="coverImage"
                        onChange={handleCoverChange}
                        className="w-full px-3 py-2 border rounded-md"
                    />
                    {house.coverImage && (
                        <div className="mt-4">
                            <h3 className="font-bold">Current Cover Image</h3>
                            <img src={house.coverImage} alt="Cover" className="w-full h-64 object-cover rounded-md" />
                        </div>
                    )}
                </div>
                <div className="mb-4">
                    <label htmlFor="gallery" className="block mb-2 font-bold">Photo Gallery</label>
                    <input
                        type="file"
                        id="gallery"
                        name="gallery"
                        multiple
                        onChange={handleGalleryChange}
                        className="w-full px-3 py-2 border rounded-md"
                    />
                    {house.gallery && house.gallery.length > 0 && (
                        <div className="mt-4">
                            <h3 className="font-bold">Current Gallery</h3>
                            <div className="grid grid-cols-3 gap-4 mt-2">
                                {house.gallery.map((url, index) => (
                                    <img key={index} src={url} alt={`Gallery item ${index + 1}`} className="w-full h-32 object-cover rounded-md" />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <button
                    type="submit"
                    disabled={loading}
                    className="bg-teal-600 text-white px-4 py-2 rounded-md hover:bg-teal-700 disabled:bg-teal-300"
                >
                    {loading ? 'Saving...' : 'Save Post'}
                </button>
            </form>
        </div>
    );
};

export default AddEditHouse;
