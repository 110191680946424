import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddEditBlogPost = () => {
    const [post, setPost] = useState({ title: '', content: '', summary: '', imageUrl: '' });
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            const fetchPost = async () => {
                const docRef = doc(db, 'blogPosts', id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setPost({ id: docSnap.id, ...docSnap.data() });
                }
            };
            fetchPost();
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPost(prev => ({ ...prev, [name]: value }));
    };

    const handleContentChange = (content) => {
        setPost(prev => ({ ...prev, content }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            if (id) {
                const docRef = doc(db, 'blogPosts', id);
                await updateDoc(docRef, {
                    ...post,
                    updatedAt: new Date()
                });
            } else {
                await addDoc(collection(db, 'blogPosts'), {
                    ...post,
                    createdAt: new Date(),
                    updatedAt: new Date()
                });
            }
            navigate('/admin');
        } catch (error) {
            console.error('Error adding/editing blog post:', error);
        }

        setLoading(false);
    };

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">{id ? 'Edit' : 'Add'} Blog Post</h1>
            <form onSubmit={handleSubmit} className="max-w-4xl">
                <div className="mb-4">
                    <label htmlFor="title" className="block mb-2 font-bold">Title</label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={post.title}
                        onChange={handleChange}
                        required
                        className="w-full px-3 py-2 border rounded-md"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="summary" className="block mb-2 font-bold">Summary</label>
                    <textarea
                        id="summary"
                        name="summary"
                        value={post.summary}
                        onChange={handleChange}
                        required
                        rows="3"
                        className="w-full px-3 py-2 border rounded-md"
                    ></textarea>
                </div>
                <div className="mb-4">
                    <label htmlFor="content" className="block mb-2 font-bold">Content</label>
                    <ReactQuill
                        value={post.content}
                        onChange={handleContentChange}
                        modules={modules}
                        formats={formats}
                        className="bg-white"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="imageUrl" className="block mb-2 font-bold">Image URL</label>
                    <input
                        type="url"
                        id="imageUrl"
                        name="imageUrl"
                        value={post.imageUrl}
                        onChange={handleChange}
                        className="w-full px-3 py-2 border rounded-md"
                    />
                </div>
                <button
                    type="submit"
                    disabled={loading}
                    className="bg-teal-600 text-white px-4 py-2 rounded-md hover:bg-teal-700 disabled:bg-teal-300"
                >
                    {loading ? 'Saving...' : 'Save Post'}
                </button>
            </form>
        </div>
    );
};

export default AddEditBlogPost;