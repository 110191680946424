import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';

// Import components
import Header from './components/Header';
import Footer from './components/Footer';
import CookieConsent from './components/CookieConsent';
import ScrollToTop from './components/ScrollToTop';

// Import pages
import Home from './pages/Home';
import About from './pages/About';
// import MentalHealthSupport from './pages/MentalHealthSupport';
import AutismSupport from './pages/AutismSupport';
import PhysicalDisabilitySupport from './pages/PhysicalDisabilitySupport';
import LearningDisabilitySupport from './pages/LearningDisabilitySupport';
import ResidentialCare from './pages/ResidentialCare';
import SupportedLiving from './pages/SupportedLiving';
import Contact from './pages/Contact';
import BlogList from './pages/BlogList';
import BlogPost from './pages/BlogPost';
import Login from './pages/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Admin from './pages/Admin';
import AddTestimonial from './pages/AddTestimonial';
import AddEditBlogPost from './pages/AddEditBlogPost';
import AddEditHouse from './pages/AddEditHouse';
import NotFound from './pages/404';
import PrivacyPolicy from './pages/PrivacyPolicy';
import OurHomes from './pages/OurHomes';
import House from './pages/House';
import RespiteCare from './pages/RespiteCare';

/**
 * App component is the main component that sets up the application routes and handles global states.
 * It includes the Router, authentication state handling, and renders the common layout including header, footer, and other global components.
 * 
 * @returns {JSX.Element} The rendered App component.
 */
const App = () => {
  // Hook to get the current user authentication state
  const [user, loading] = useAuthState(auth);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  /**
   * Handles the visibility of the header component based on the user's scroll position.
   * 
   * This effect is used to hide the header when the user scrolls down more than 100 pixels,
   * and show it again when the user scrolls back up.
   * 
   * @returns {Function} A cleanup function to remove the scroll event listener.
   */
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY < lastScrollY) {
        setIsHeaderVisible(true);
      } else if (currentScrollY > 100) {
        setIsHeaderVisible(false);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  // Display a loading screen while the authentication state is being determined
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      {/* ScrollToTop ensures the user is taken to the top of the page on route change */}
      <ScrollToTop />

      <div className="flex flex-col min-h-screen">
        {/* Helmet for managing document head data like title, meta tags, etc. */}
        <Helmet>
          <title>Sandbourne Care | Compassionate Care Services in Bournemouth</title>
          <meta name="description" content="Sandbourne Care provides high-quality residential and supported living services in Bournemouth. Specialized care for mental health, autism, and physical disabilities." />
          <meta name="keywords" content="Sandbourne Care, residential care, supported living, Bournemouth, mental health care, autism support, physical disability care" />
          <meta property="og:title" content="Sandbourne Care | Compassionate Care Services in Bournemouth" />
          <meta property="og:description" content="High-quality residential and supported living services in Bournemouth. Specialized care for mental health, autism, and physical disabilities." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.sandbournecare.co.uk" />
          <meta property="og:image" content="https://www.sandbournecare.co.uk/hero.jpeg" />
          <link rel="canonical" href="https://www.sandbournecare.co.uk" />
        </Helmet>

        {/* Header component with user state passed as a prop */}
        <Header user={user} isVisible={isHeaderVisible} />

        {/* Main content area where routes will be rendered */}
        <main className="flex-grow pt-16">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/our-homes" element={<OurHomes />} />
            <Route path="/our-homes/:slug" element={<House />} />
            {/* <Route path="/support/mental-health" element={<MentalHealthSupport />} /> */}
            <Route path="/support/autism" element={<AutismSupport />} />
            <Route path="/support/physical-disability" element={<PhysicalDisabilitySupport />} />
            <Route path="/support/learning-disability" element={<LearningDisabilitySupport />} />
            <Route path="/services/residential-care" element={<ResidentialCare />} />
            <Route path="/services/supported-living" element={<SupportedLiving />} />
            <Route path="/services/respite-care" element={<RespiteCare />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/our-stories" element={<BlogList />} />
            <Route path="/our-stories/:id" element={<BlogPost />} />
            <Route path="/login" element={<Login />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            {/* Protected Routes are accessible only to authenticated users */}
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <Admin />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/add-testimonial"
              element={
                <ProtectedRoute>
                  <AddTestimonial />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/add-house"
              element={
                <ProtectedRoute>
                  <AddEditHouse />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/edit-house/:id"
              element={
                <ProtectedRoute>
                  <AddEditHouse />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/add-blog-post"
              element={
                <ProtectedRoute>
                  <AddEditBlogPost />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/edit-blog-post/:id"
              element={
                <ProtectedRoute>
                  <AddEditBlogPost />
                </ProtectedRoute>
              }
            />

            {/* Route for handling 404 not found */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>

        {/* Footer component */}
        <Footer />

        {/* CookieConsent component for handling cookie consent banner */}
        <CookieConsent />
      </div>

      {/* Toaster for showing toast notifications at the bottom-right corner of the screen */}
      <Toaster position="bottom-right" />
    </Router>
  );
};

export default App;
