/**
 * Home Component
 * 
 * This component represents the main page of the Sandbourne Care website.
 * It displays various sections including a hero, services, support, testimonials,
 * reasons to choose Sandbourne Care, recent blog posts, and a call to action.
 * 
 * The component fetches blog posts and home information from a Firestore database
 * and displays them in their respective sections.
 * 
 * @component
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, Users, Brain, Book, House, Accessibility } from 'lucide-react';
import { collection, getDocs, orderBy, query, limit } from 'firebase/firestore';
import { db } from '../firebase';
import TestimonialSlider from '../components/TestimonialSlider';
import whyImage from '../assets/images/why.jpeg';
import Seo from '../components/Seo';
import heroImage from '../assets/images/hero.jpeg';
import ServiceCard from '../components/ServiceCard';
import { motion } from 'framer-motion';
import LoadingLogo from '../components/LoadingLogo';

const Home = () => {
    const [posts, setPosts] = useState([]);
    const [homes, setHomes] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([fetchPosts(), fetchHomes()]);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    /**
     * Fetches blog posts from Firestore
     * @async
     */
    const fetchPosts = async () => {
        const q = query(collection(db, 'blogPosts'), orderBy('createdAt', 'desc'), limit(6));
        const querySnapshot = await getDocs(q);
        const fetchedPosts = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        setPosts(fetchedPosts);
    };

    /**
     * Fetches home information from Firestore
     * @async
     */
    const fetchHomes = async () => {
        const q = query(collection(db, 'ourHomes'), orderBy('title', 'desc'));
        const querySnapshot = await getDocs(q);
        const fetchedHomes = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        setHomes(fetchedHomes);
    };

    if (loading) {
        return <LoadingLogo />;
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="overflow-x-hidden"
        >
            <Seo
                title="Sandbourne Care | Quality Care Services in Bournemouth"
                description="Sandbourne Care offers compassionate residential and supported living services in Bournemouth. Specialized care for mental health, autism, and physical disabilities."
                keywords="Sandbourne Care, residential care, supported living, Bournemouth, mental health care, autism support, physical disability care"
                canonicalUrl="https://www.sandbournecare.co.uk"
                ogType="website"
                ogTitle="Sandbourne Care | Care Services in Bournemouth"
                ogDescription="Discover our range of care services at Sandbourne Care, providing compassionate support for mental health, autism, and physical disabilities in Bournemouth."
                ogImage={heroImage}
                ogUrl="https://www.sandbournecare.co.uk"
            />

            <HeroSection />
            <ServicesSection />
            <SupportSection />
            <TestimonialSlider />
            <WhyChooseSection />
            <OurStoriesSection posts={posts} />
            <CallToActionSection homes={homes} />
        </motion.div>
    );
};

export default Home;

/**
 * Hero Section Component
 * @returns {JSX.Element}
 */
const HeroSection = () => (
    <section className="relative bg-gradient-to-r from-teal-600 to-teal-400 text-white py-20 px-4">
        <div className="container mx-auto max-w-6xl flex flex-col md:flex-row items-center gap-10">
            <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className="md:w-1/2 mb-8 md:mb-0"
            >
                <h1 className="text-4xl md:text-5xl font-bold mb-6">
                    Welcome to Sandbourne Care
                </h1>
                <p className="text-xl md:text-2xl mb-8">
                    Delivering compassionate and specialised care with dedication, tailored to meet the needs of those who require it most.
                </p>
                <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <Link
                        to="/about"
                        className="bg-white text-teal-600 font-semibold px-6 py-3 rounded-full hover:bg-teal-50 transition inline-flex items-center"
                    >
                        Learn More About Us <ChevronRight className="ml-2 w-5 h-5 transition-transform duration-300 ease-out group-hover:translate-x-1" />
                    </Link>
                </motion.div>
            </motion.div>
            <motion.div
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className="md:w-1/2 relative"
            >
                <img
                    src={heroImage}
                    alt="hero"
                    loading="lazy"
                    className="rounded-lg shadow-lg w-full h-auto"
                />
                <div className="absolute inset-0 bg-teal-600 opacity-20 rounded-lg"></div>
            </motion.div>
        </div>
    </section>
);

/**
 * Services Section Component
 * @returns {JSX.Element}
 */
const ServicesSection = () => (
    <section className="py-16 px-4 bg-gray-100">
        <div className="container mx-auto max-w-6xl">
            <motion.h2
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-3xl font-bold text-center mb-12"
            >
                Our Services
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <ServiceCard
                    icon={House}
                    title="Residential Care"
                    description="We provide a safe, comfortable, and supportive environment in our residential care, where individuals receive personalised care tailored to their needs, ensuring they live with dignity and respect."
                    link="/services/residential-care"
                />
                <ServiceCard
                    icon={Accessibility}
                    title="Supported living"
                    description="We offer supported living services that empower individuals to live independently with the right level of care and support, tailored to their unique needs and preferences."
                    link="/services/supported-living"
                />
                <ServiceCard
                    icon={Users}
                    title="Respite Care"
                    description="We provide respite care to give caregivers a much-needed break, offering temporary, high-quality care and support for your loved ones in a safe and nurturing environment."
                    link="/services/respite-care"
                />
            </div>
        </div>
    </section>
);

/**
 * Support Section Component
 * @returns {JSX.Element}
 */
const SupportSection = () => (
    <section className="py-16 px-4">
        <div className="container mx-auto max-w-6xl">
            <motion.h2
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-3xl font-bold text-center mb-12"
            >
                We are here to help you
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {/* <ServiceCard
                    icon={Heart}
                    title="Mental Health Care"
                    description="We provide compassionate mental health care, offering tailored support to help individuals manage their wellbeing and live fulfilling lives."
                    link="/support/mental-health"
                /> */}
                <ServiceCard
                    icon={Users}
                    title="Autism Support"
                    description="We offer specialised autism support, creating a nurturing environment that fosters independence and personal growth for individuals with autism."
                    link="/support/autism"
                />
                <ServiceCard
                    icon={Brain}
                    title="Physical Disability Care"
                    description="We provide tailored care for individuals with physical disabilities, ensuring they receive the support they need to lead active, fulfilling lives with dignity and independence."
                    link="/support/physical-disability"
                />
                <ServiceCard
                    icon={Book}
                    title="Adult Learning Disabilities"
                    description="We offer dedicated support for adults with learning disabilities, focusing on empowering individuals to develop skills, build confidence, and achieve greater independence in their daily lives."
                    link="/support/learning-disability"
                />
            </div>
        </div>
    </section>
);

/**
 * Why Choose Sandbourne Care Section Component
 * @returns {JSX.Element}
 */
const WhyChooseSection = () => (
    <section className="py-16 px-4">
        <div className="container mx-auto max-w-6xl">
            <div className="flex flex-col md:flex-row items-center">
                <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                    className="md:w-1/2 mb-8 md:mb-0 md:pr-8"
                >
                    <h2 className="text-3xl font-bold mb-4">Why Choose Sandbourne Care?</h2>
                    <ul className="space-y-4">
                        <li className="flex items-start">
                            <ChevronRight className="w-5 h-5 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                            <span>Experienced and compassionate staff</span>
                        </li>
                        <li className="flex items-start">
                            <ChevronRight className="w-5 h-5 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                            <span>Personalised care plans tailored to individual needs</span>
                        </li>
                        <li className="flex items-start">
                            <ChevronRight className="w-5 h-5 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                            <span>Consistent high quality care provided in a dignified environment</span>
                        </li>
                        <li className="flex items-start">
                            <ChevronRight className="w-5 h-5 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                            <span>Commitment to promoting independence and quality of life</span>
                        </li>
                    </ul>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                    className="md:w-1/2"
                >
                    <img src={whyImage} alt="Sandbourne Care facility" className="rounded-lg shadow-lg w-full h-auto" />
                </motion.div>
            </div>
        </div>
    </section>
);

/**
 * Our Stories Section Component
 * @param {Object[]} posts - Array of blog posts
 * @returns {JSX.Element}
 */
const OurStoriesSection = ({ posts }) => (
    <section className="py-16 px-4 bg-gray-100">
        <div className="container mx-auto px-4 py-8">
            <motion.h2
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-3xl font-bold mb-4"
            >
                Our Stories
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {posts.map((post, index) => (
                    <motion.div
                        key={post.id}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                        className="bg-white rounded-lg shadow-md overflow-hidden"
                    >
                        <img src={post.imageUrl || 'https://via.placeholder.com/400x200'} alt={post.title} className="w-full h-48 object-cover" />
                        <div className="p-4">
                            <h2 className="text-xl font-semibold mb-2">{post.title}</h2>
                            <Link to={`/our-stories/${post.id}`} className="text-teal-600 hover:text-teal-700">Read more</Link>
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    </section>
);

/**
 * Call to Action Section Component
 * @param {Object[]} homes - Array of home information
 * @returns {JSX.Element}
 */
const CallToActionSection = ({ homes }) => (
    <section className="bg-teal-600 text-white py-16 px-4">
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="container mx-auto max-w-4xl text-center"
        >
            <h2 className="text-3xl font-bold mb-8">Ready to Learn More?</h2>
            <p className="text-xl mb-8">Contact us today to schedule a consultation or tour of our facilities.</p>
            <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
            >
                <Link to="/contact" className="bg-white text-teal-600 font-semibold px-6 py-3 rounded-full hover:bg-teal-50 transition inline-flex items-center">
                    Contact Us <ChevronRight className="ml-2 w-5 h-5" />
                </Link>
            </motion.div>
        </motion.div>
    </section>
)