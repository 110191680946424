import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import Seo from '../components/Seo';  // Import the Seo component
import LoadingLogo from '../components/LoadingLogo';

const BlogPost = () => {
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const fetchPost = async () => {
            const docRef = doc(db, 'blogPosts', id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setPost({ id: docSnap.id, ...docSnap.data() });
            } else {
                console.log('No such document!');
            }
            setLoading(false);
        };

        fetchPost();
    }, [id]);

    if (loading) {
        return <LoadingLogo />;
    }

    if (!post) {
        return <div>Post not found</div>;
    }

    return (
        <div className="container mx-auto px-4 py-8">
            {/* SEO Component for optimizing the Blog Post page */}
            <Seo
                title={post.title}
                description={post.summary || 'Read this insightful blog post on our site.'}
                keywords="blog, Sandbourne Care, care services, insights"
                canonicalUrl={`https://www.sandbournecare.co.uk/our-stories/${post.id}`}
                ogType="article"
                ogTitle={post.title}
                ogDescription={post.summary || 'Read this insightful blog post on our site.'}
                ogImage={post.imageUrl || 'https://via.placeholder.com/800x400'}  // Replace with actual image path if available
                ogUrl={`https://www.sandbournecare.co.uk/our-stories/${post.id}`}
            />

            <h1 className="text-3xl font-bold mb-4">{post.title}</h1>
            <img src={post.imageUrl || 'https://via.placeholder.com/800x400'} alt={post.title} className="w-full h-64 object-cover rounded-lg mb-6" />
            <div className="prose max-w-none">
                <ReactQuill
                    value={post.content}
                    readOnly={true}
                    theme="bubble"
                />
            </div>
        </div>
    );
};

export default BlogPost;
