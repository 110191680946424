import React from 'react';
import { Home, Users, Briefcase, Clock, Star, Heart, Book, Activity, MessageCircle } from 'lucide-react';
import { motion } from 'framer-motion';
import Seo from '../components/Seo';
import FeatureCard from '../components/FeatureCard';

import hero from '../assets/images/supported_living.jpeg';
import supportedImg from '../assets/images/supported_living_service.jpeg';

/**
 * The SupportedLiving component renders a comprehensive page for Supported Living services.
 * It includes various sections that outline the services provided, the support available, 
 * and the commitment to promoting independence and well-being for individuals.
 * The component now includes animations for a more engaging user experience.
 *
 * @component
 * @example
 * return (
 *   <SupportedLiving />
 * )
 */
const SupportedLiving = () => {
    const features = [
        {
            icon: Home,
            title: "Independent Living",
            description: "We support individuals to live independently in their own homes or shared accommodations."
        },
        {
            icon: Users,
            title: "Personalised Support",
            description: "Our support plans are tailored to each individual's unique needs and goals."
        },
        {
            icon: Briefcase,
            title: "Skills Development",
            description: "We help individuals develop life skills necessary for independent living and employment."
        },
        {
            icon: Clock,
            title: "Flexible Support",
            description: "Our support is available when needed, from a few hours a week to 24/7 care."
        }
    ];

    return (
        <motion.div
            className="animate-fadeIn"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <Seo
                title="Supported Living Services | Sandbourne Care"
                description="Empowering individuals to live independently with personalized support plans at Sandbourne Care. Explore our Supported Living services."
                keywords="Supported Living, Sandbourne Care, independent living, personalized support, life skills development"
                canonicalUrl="https://www.sandbournecare.co.uk/supported-living"
                ogType="website"
                ogTitle="Supported Living Services | Sandbourne Care"
                ogDescription="Discover how Sandbourne Care supports independent living with tailored support plans to help individuals achieve their goals."
                ogImage={hero}
                ogUrl="https://www.sandbournecare.co.uk/supported-living"
            />

            <motion.section
                className="relative bg-gradient-to-r from-teal-600 to-teal-400 text-white py-20 px-4"
                initial={{ y: -50 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <div className="container mx-auto max-w-6xl flex flex-col md:flex-row items-center">
                    <div className="md:w-1/2 mb-8 md:mb-0">
                        <h1 className="text-4xl md:text-5xl font-bold mb-6">Supported Living Services</h1>
                        <p className="text-xl md:text-2xl mb-8">
                            Empowering individuals to live independently with the right level of support.
                        </p>
                    </div>
                    <div className="md:w-1/2 relative">
                        <img src={hero} alt="Person living independently with support" className="rounded-lg shadow-lg" />
                        <div className="absolute inset-0 bg-teal-600 opacity-20 rounded-lg"></div>
                    </div>
                </div>
            </motion.section>

            <motion.section
                className="py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-6xl">
                    <h2 className="text-3xl font-bold mb-12 text-center">Our Approach to Supported Living</h2>
                    <motion.div
                        className="grid grid-cols-1 md:grid-cols-2 gap-8"
                        variants={{
                            hidden: { opacity: 0 },
                            show: {
                                opacity: 1,
                                transition: {
                                    staggerChildren: 0.2
                                }
                            }
                        }}
                        initial="hidden"
                        animate="show"
                    >
                        {features.map((feature, index) => (
                            <motion.div
                                key={index}
                                variants={{
                                    hidden: { opacity: 0, y: 20 },
                                    show: { opacity: 1, y: 0 }
                                }}
                            >
                                <FeatureCard {...feature} />
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </motion.section>

            <motion.section
                className="bg-gray-100 py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-6xl">
                    <div className="flex flex-col md:flex-row items-center">
                        <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
                            <h2 className="text-3xl font-bold mb-6">Our Supported Living Services</h2>
                            <ul className="space-y-4 text-lg text-gray-700">
                                <li className="flex items-start">
                                    <Heart className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Assistance with daily living activities</span>
                                </li>
                                <li className="flex items-start">
                                    <Book className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Support with managing finances and budgeting</span>
                                </li>
                                <li className="flex items-start">
                                    <Users className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Help with accessing community services and activities</span>
                                </li>
                                <li className="flex items-start">
                                    <Activity className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Assistance with healthcare appointments and coordination</span>
                                </li>
                                <li className="flex items-start">
                                    <Home className="w-6 h-6 text-teal-600 mr-2 flex-shrink-0 mt-1" />
                                    <span>Support in maintaining tenancies and managing households</span>
                                </li>
                            </ul>
                        </div>
                        <div className="md:w-1/2">
                            <img src={supportedImg} alt="Supported living environment" className="rounded-lg shadow-lg" />
                        </div>
                    </div>
                </div>
            </motion.section>

            <motion.section
                className="py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-6xl">
                    <h2 className="text-3xl font-bold mb-8 text-center">Promoting Independence and Well-being</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold mb-4">Personal Growth</h3>
                            <ul className="space-y-2 text-gray-700">
                                <li>• Developing life skills</li>
                                <li>• Building self-confidence</li>
                                <li>• Encouraging decision-making</li>
                            </ul>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold mb-4">Community Integration</h3>
                            <ul className="space-y-2 text-gray-700">
                                <li>• Accessing local services</li>
                                <li>• Participating in community events</li>
                                <li>• Building social networks</li>
                            </ul>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h3 className="text-xl font-semibold mb-4">Health and Wellness</h3>
                            <ul className="space-y-2 text-gray-700">
                                <li>• Promoting active lifestyles</li>
                                <li>• Supporting mental health</li>
                                <li>• Assisting with healthcare management</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </motion.section>

            <motion.section
                className="bg-teal-50 py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.8, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-4xl text-center">
                    <h2 className="text-3xl font-bold mb-6">Our Commitment to Quality</h2>
                    <p className="text-lg text-gray-700 mb-8">
                        We are committed to providing high-quality supported living services that meet the individual needs of each person we support. Our quality assurance measures include:
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                        <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
                            <Star className="w-8 h-8 text-teal-600 mr-4" />
                            <span>Regular reviews and assessments</span>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
                            <Star className="w-8 h-8 text-teal-600 mr-4" />
                            <span>Ongoing staff training and development</span>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
                            <Star className="w-8 h-8 text-teal-600 mr-4" />
                            <span>Person-centred support planning</span>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
                            <Star className="w-8 h-8 text-teal-600 mr-4" />
                            <span>Regular feedback and satisfaction surveys</span>
                        </div>
                    </div>
                </div>
            </motion.section>

            <motion.section
                className="py-16 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1, duration: 0.5 }}
            >
                <div className="container mx-auto max-w-4xl text-center">
                    <h2 className="text-3xl font-bold mb-6">Explore Our Supported Living Services</h2>
                    <p className="text-lg text-gray-700 mb-8">
                        If you or a loved one is interested in supported living, we're here to help. Our team can provide more information, assess your needs, and discuss how our services can support your journey towards greater independence.
                    </p>
                    <a href="/contact" className="btn-primary inline-flex items-center">
                        Contact Us Today <MessageCircle className="ml-2 w-5 h-5" />
                    </a>
                </div>
            </motion.section>
        </motion.div>
    );
};

export default SupportedLiving;